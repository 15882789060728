
<template>
  <!-- The modal container with draggable and resizable features -->
  <div
    class="drag-bg"
    @mousemove="doDrag($event)"
    @mouseup="stopDrag()"
    ref="dragBg"
  >
    <div class="card drag-content" ref="dragCard">
      <div
        v-if="draggable"
        class="dragger"
        @mousedown="mouseDownDrag($event)"
        @mouseup="stopDrag()"
        ref="dragger"
      >
        <img
          :src="getDraggerImage()"
          style="pointer-events: none; width: 100%; height: 100%"
        />
      </div>
      <!-- Resizable handles for all sides and corners of the modal -->
      <div
        class="resize-n"
        @mousedown="mouseDownResizeN($event)"
        @mouseup="stopDrag()"
      ></div>
      <div
        class="resize-w"
        @mousedown="mouseDownResizeW($event)"
        @mouseup="stopDrag()"
      ></div>
      <div
        class="resize-s"
        @mousedown="mouseDownResizeS($event)"
        @mouseup="stopDrag()"
      ></div>
      <div
        class="resize-e"
        @mousedown="mouseDownResizeE($event)"
        @mouseup="stopDrag()"
      ></div>
      <div
        class="resize-nw"
        @mousedown="mouseDownResizeNW($event)"
        @mouseup="stopDrag()"
      ></div>
      <div
        class="resize-ne"
        @mousedown="mouseDownResizeNE($event)"
        @mouseup="stopDrag()"
      ></div>
      <div
        class="resize-sw"
        @mousedown="mouseDownResizeSW($event)"
        @mouseup="stopDrag()"
      ></div>
      <div
        class="resize-se"
        @mousedown="mouseDownResizeSE($event)"
        @mouseup="stopDrag()"
      ></div>
      <div class="flex flex-column">
        <div>
          <template v-if="$slots.header">
            <slot name="header" v-bind="slotProps"></slot>
          </template>
        </div>
        <br />
        <div>
          <template v-if="$slots.body">
            <slot name="body" v-bind="slotProps"></slot>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { ref } from "vue";

export default {
  data() {
    return {
      // Variables to track modal state and dragging/resizing information
      visible: false,
      startX: 0,
      startY: 0,
      startWidth: 0,
      startHeight: 0,
      startTop: 0,
      startLeft: 0,
      newHeight: null,
      newWidth: null,
      newTop: null,
      newLeft: null,
      lastLeft: null,
      lastTop: null,
      lastWidth: null,
      lastHeight: null,
      isDraggingCard: false,
      isDraggingResizeN: false,
      isDraggingResizeW: false,
      isDraggingResizeE: false,
      isDraggingResizeS: false,
      isDraggingResizeNW: false,
      isDraggingResizeNE: false,
      isDraggingResizeSW: false,
      isDraggingResizeSE: false,
      configData: {
        hasBG: false,
      }
    };
  },
  props: {
    minWidth: Number,
    minHeight: Number,
    initialLeft: Number,
    initialTop: Number,
    draggable: Boolean,
  },
  mounted() {
    var positionDataString = localStorage.getItem("notepadPosition");
    var configDataString = localStorage.getItem("notepadConfig");
    if (
      positionDataString !== null &&
      positionDataString !== "" &&
      positionDataString !== undefined &&
      positionDataString !== "null"
    ) {
      let startData = JSON.parse(positionDataString);
      this.$refs.dragCard.style.left = startData.left ;
      this.$refs.dragCard.style.top = startData.top ;
      this.$refs.dragCard.style.height = startData.height ;
      this.$refs.dragCard.style.width = startData.width ;
    } else {
      this.$refs.dragCard.style.left = this.initialLeft + 'px';
      this.$refs.dragCard.style.top = this.initialTop + 'px';
      this.$refs.dragCard.style.height = this.minHeight + 'px';
      this.$refs.dragCard.style.width = this.minWidth + 'px';
    }
    if (
      configDataString !== null &&
      configDataString !== "" &&
      configDataString !== undefined &&
      configDataString !== "null"
    ) {
      let config = JSON.parse(configDataString)
      if (Object.prototype.hasOwnProperty.call(config, 'hasBG')){
        this.configData.hasBG = config.hasBG
      }
    }
    // this.checkBoundaries("drag");
    // this.checkBoundaries("resize");
  },
  watch:{
    configData: {
      handler: function () {
      },
      deep: true,
    },
  },
  methods: {
    resetPosition(){
      this.newLeft = this.initialLeft ;
      this.newTop = this.initialTop ;
      this.newHeight = this.minHeight ;
      this.newWidth = this.minWidth ;
      this.checkBoundaries("resize")
    },
    toggleBG(){
      this.configData.hasBG = !this.configData.hasBG
      this.saveLocalStorage() 
      if (this.configData.hasBG){
          this.$refs['dragBg'].style['background-color'] = "#00000032"
        }else{
          this.$refs['dragBg'].style['background-color'] = "#00000000"

        }
    },
    saveLocalStorage() {
      localStorage.setItem(
        "notepadPosition",
        JSON.stringify({
          left: this.$refs.dragCard.style.left,
          top: this.$refs.dragCard.style.top,
          height: this.$refs.dragCard.style.height,
          width: this.$refs.dragCard.style.width,
        })
      );
      localStorage.setItem("notepadConfig", JSON.stringify(this.configData))
    },
    checkBoundaries(action) {
      if (action == "resize") {
        if (this.newHeight < this.minHeight) {
          this.newHeight = this.lastHeight;
          this.newTop = this.lastTop;
        }
        if (this.newWidth < this.minWidth) {
          this.newWidth = this.lastWidth;
          this.newLeft = this.lastLeft;
        }
        this.$refs.dragCard.style.height = this.newHeight + "px";
        this.$refs.dragCard.style.width = this.newWidth + "px";
        this.$refs.dragCard.style.top = this.newTop + "px";
        this.$refs.dragCard.style.left = this.newLeft + "px";
        this.lastLeft = this.newLeft;
        this.lastTop = this.newTop;
        this.lastWidth = this.newWidth;
        this.lastHeight = this.newHeight;

        this.saveLocalStorage();
      }
      if (action == "drag") {
        // Limit modal position within the viewport
        if (
          this.newLeft + this.startWidth <
          ref(window.innerWidth)._value - 10
        ) {
          this.$refs.dragCard.style.left = this.newLeft + "px";
          this.saveLocalStorage();
        } else {
          this.$refs.dragCard.style.left =
            ref(window.innerWidth)._value - this.startWidth - 10 + "px";

          this.saveLocalStorage();
          return;
        }
        if (this.newLeft > 10) {
          this.$refs.dragCard.style.left = this.newLeft + "px";
          this.saveLocalStorage();
        } else {
          this.$refs.dragCard.style.left = 10 + "px";
          this.saveLocalStorage();

          return;
        }

        if (this.newTop + this.startHeight < window.innerHeight - 10) {
          this.$refs.dragCard.style.top = this.newTop + "px";
          this.saveLocalStorage();
        } else {
          this.$refs.dragCard.style.top =
            window.innerHeight - this.startHeight - 10 + "px";
          this.saveLocalStorage();

          return;
        }
        if (this.newTop > 30) {
          this.$refs.dragCard.style.top = this.newTop + "px";
          this.saveLocalStorage();
        } else {
          this.$refs.dragCard.style.top = 30 + "px";
          this.saveLocalStorage();

          return;
        }
      }
    },
    // Helper function to calculate initial values for dragging and resizing
    mouseDownResizeCalc(e) {
      this.startX = e.clientX;
      this.startY = e.clientY;
      this.startLeft = parseInt(
        document.defaultView.getComputedStyle(this.$refs.dragCard).left,
        10
      );
      this.startWidth = parseInt(
        document.defaultView.getComputedStyle(this.$refs.dragCard).width,
        10
      );
      this.startHeight = parseInt(
        document.defaultView.getComputedStyle(this.$refs.dragCard).height,
        10
      );
      this.startTop = parseInt(
        document.defaultView.getComputedStyle(this.$refs.dragCard).top,
        10
      );
      this.$refs.dragBg.style["pointer-events"] = "all";
      e.stopPropagation();
    },
    // Event handlers for resizing in different directions
    mouseDownResizeN(e) {
      this.mouseDownResizeCalc(e);
      this.isDraggingResizeN = true;
    },
    mouseDownResizeW(e) {
      this.mouseDownResizeCalc(e);
      this.isDraggingResizeW = true;
    },
    mouseDownResizeS(e) {
      this.mouseDownResizeCalc(e);
      this.isDraggingResizeS = true;
    },
    mouseDownResizeE(e) {
      this.mouseDownResizeCalc(e);
      this.isDraggingResizeE = true;
    },
    mouseDownResizeNW(e) {
      this.mouseDownResizeCalc(e);
      this.isDraggingResizeNW = true;
    },
    mouseDownResizeNE(e) {
      this.mouseDownResizeCalc(e);
      this.isDraggingResizeNE = true;
    },
    mouseDownResizeSW(e) {
      this.mouseDownResizeCalc(e);
      this.isDraggingResizeSW = true;
    },
    mouseDownResizeSE(e) {
      this.mouseDownResizeCalc(e);
      this.isDraggingResizeSE = true;
    },
    // Event handler for starting modal dragging
    mouseDownDrag(e) {
      this.mouseDownResizeCalc(e);
      this.$refs.dragBg.style["pointer-events"] = "auto";
      this.isDraggingCard = true;
      if (this.draggable) {
        this.$refs.dragger.style.cursor = "grabbing";
      }
    },
    // Function to handle modal dragging and resizing
    doDrag(e) {
      if (this.isDraggingCard) {
        this.newTop = this.startTop + e.clientY - this.startY;
        this.newLeft = this.startLeft + e.clientX - this.startX;
        this.checkBoundaries("drag");

        return;
      }
      // Handle resizing in different directions
      if (this.isDraggingResizeN) {
        this.newHeight = this.startHeight - e.clientY + this.startY;
        this.newTop = this.startTop + e.clientY - this.startY;
        this.checkBoundaries("resize");
        return;
      }
      if (this.isDraggingResizeW) {
        this.newWidth = this.startWidth - e.clientX + this.startX;
        this.newLeft = this.startLeft + e.clientX - this.startX;
        this.checkBoundaries("resize");
        return;
      }
      if (this.isDraggingResizeS) {
        this.newHeight = this.startHeight + e.clientY - this.startY;
        this.checkBoundaries("resize");
        return;
      }
      if (this.isDraggingResizeE) {
        this.newWidth = this.startWidth + e.clientX - this.startX;
        this.checkBoundaries("resize");
        return;
      }
      if (this.isDraggingResizeNW) {
        this.newHeight = this.startHeight - e.clientY + this.startY;
        this.newWidth = this.startWidth - e.clientX + this.startX;
        this.newLeft = this.startLeft + e.clientX - this.startX;
        this.newTop = this.startTop + e.clientY - this.startY;
        this.checkBoundaries("resize");
        return;
      }
      if (this.isDraggingResizeNE) {
        this.newHeight = this.startHeight - e.clientY + this.startY;
        this.newWidth = this.startWidth + e.clientX - this.startX;
        this.newTop = this.startTop + e.clientY - this.startY;
        this.checkBoundaries("resize");
        return;
      }
      if (this.isDraggingResizeSW) {
        this.newHeight = this.startHeight + e.clientY - this.startY;
        this.newWidth = this.startWidth - e.clientX + this.startX;
        this.newLeft = this.startLeft + e.clientX - this.startX;
        this.checkBoundaries("resize");
        return;
      }
      if (this.isDraggingResizeSE) {
        this.newHeight = this.startHeight + e.clientY - this.startY;
        this.newWidth = this.startWidth + e.clientX - this.startX;
        this.checkBoundaries("resize");
        return;
      }

      e.preven;
    },
    // Function to stop modal dragging and resizing
    stopDrag() {
      if (this.draggable) {
        this.$refs.dragger.style.cursor = "grab";
      }
      this.$refs.dragBg.style["pointer-events"] = "none";
      this.isDraggingCard = false;
      this.isDraggingResizeN = false;
      this.isDraggingResizeW = false;
      this.isDraggingResizeS = false;
      this.isDraggingResizeE = false;
      this.isDraggingResizeNW = false;
      this.isDraggingResizeNE = false;
      this.isDraggingResizeSW = false;
      this.isDraggingResizeSE = false;
    },
    getDraggerImage() {
      return "images/logo_only_cloud_storm_black_font.png";
    },
    test() {},
  },
};
</script>

<style>
.dragger {
  pointer-events: all;
  width: 30px;
  height: 30px;
  position: absolute;
  left: calc(50% - 15px);
  top: -20px;

  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  cursor: grab;
}

/* Styles for the draggable and resizable modal */
.drag-content {
  padding: 0px 10px 0px 10px !important;
  width: 200px;
  height: 100px;
  position: absolute;
  z-index: 1100;
  pointer-events: all;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 0px 2px rgba(0, 0, 0, 0.1),
    0px 1px 4px rgba(0, 0, 0, 0.2) !important;
}

.drag-content.resizable {
  position: relative;
}

.drag-content .resize-n {
  width: 100%;
  height: 10px;
  position: absolute;
  left: 0;
  top: 0;
  cursor: n-resize;
}

.drag-content .resize-w {
  width: 10px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  cursor: w-resize;
}

.drag-content .resize-s {
  width: 100%;
  height: 10px;
  position: absolute;
  left: 0;
  bottom: 0;
  cursor: s-resize;
}

.drag-content .resize-e {
  width: 10px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  cursor: e-resize;
}

.drag-content .resize-nw {
  width: 10px;
  height: 10px;
  position: absolute;
  left: 0;
  top: 0;
  cursor: nw-resize;
}

.drag-content .resize-ne {
  width: 10px;
  height: 10px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: ne-resize;
}

.drag-content .resize-sw {
  width: 10px;
  height: 10px;
  position: absolute;
  left: 0;
  bottom: 0;
  cursor: sw-resize;
}

.drag-content .resize-se {
  width: 10px;
  height: 10px;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: se-resize;
}

.drag-bg {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  pointer-events: none;
  background-color: #00000000;
  z-index: 1000;
}
</style>