
<template>
  <div class="widthfitter">
    <div
      class="content"
      ref="content"
      @mouseenter="expandMenu($event)"
      @mouseleave="collapseMenu($event)"
    >
      <div ref="expander" class="expander flex justify-content-center flex-column">
        <div style="left: 10px; position: relative">
          <template v-if="$slots.expander">
            <slot name="expander" v-bind="slotProps"></slot>
          </template>
        </div>
      </div>
      <div ref="menu-content" class="card menu-content">
        <template v-if="$slots.content">
          <slot name="content" v-bind="slotProps"></slot>
        </template>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {};
  },
  props: {
  },
  watch: {
  },
  mounted() {
    var menuContentHeight = parseInt(
      document.defaultView.getComputedStyle(this.$refs["menu-content"]).height,
      10
    );
    this.$refs["content"].style.height = menuContentHeight + "px";
    // menuContentHeight = menuContentHeight
    this.$refs["content"].style.top = "calc(50% - " + menuContentHeight/2 + "px)";
    this.$refs["menu-content"].style.top = "calc(50% - " + menuContentHeight/2 + "px)";
    this.$refs["expander"].style.top = "calc(50% - 15px)";
  },
  methods: {
    expandMenu() {
      this.$refs.content.style.right = "-20px";
    },
    collapseMenu() {
      this.$refs.content.style.right = "-80px";
    },
    test() {},
  },
};
</script>

<style>
.widthfitter {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}

.content {
  position: absolute;
  right: -80px;
  width: 80px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -ms-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  pointer-events: all;
}

.menu-content {
  padding: 15px;
  position: absolute;
  right: 10px;
  max-width: 70px;
  width: 70px;
}

.expander {
  position: relative;
  width: 40px;
  height: 30px;
  right: 30px;
  background-color: var(--surface-card);
  color: var(--surface-900);
  border-radius: 10px;
  box-shadow: -2px 0px 2px 0px rgba(0, 0, 0, 0.08);
  z-index: 10;
}
</style>