<template>
  <div>
    <!-- <div v-if="loadingComponent == false">
      <card>
        <div class="text-center">
          {{ this.$langGetValue("incident") }}
        </div>
        <div>
          <Skeleton width="10rem" height="4rem"></Skeleton>
        </div>
      </card>
    </div> -->
    <div ref="incidentLabel" style="scroll-margin-top: 100px" class="grid">
      <Toast />

      <!-- Inicio notepad -->
      <DragDialog v-show="openNotepad" class="notepadDialog" :minHeight="300" :minWidth="300" :initialLeft="100"
        :initialTop="100" :draggable="true" ref="drag-dialog">
        <template #header="">
          <div style="
              height: 20px;
              padding: 10px 0px 0px 0px !important;
              width: 100%;
            ">
            <i style="font-size: 1.3rem" class="pi pi-comments"></i><i class="pi pi-save notepad-icon-save"
              ref="notepad-icon-save"></i>
            <i class="pi pi-times" style="float: right; cursor: pointer" @click="this.openNotepad = false"></i>
          </div>
        </template>

        <template #body="">
          <div style="height: calc(100% - 20px); width: 100%" class="comments" id="notepad-container"></div>
        </template>
      </DragDialog>

      <ExpandMenu class="notepadExpand" v-if="checkRole(['team.op-risk'])" v-show="incidentDataLocal != undefined"
        style="position: fixed; z-index: 999" ref="expand-menu">
        <template #expander="">
          <i class="pi pi-angle-double-left" style="cursor:pointer"></i>
        </template>
        <template #content="">
          <i class="pi pi-comments" @click="
            this.openNotepad == true
              ? (this.openNotepad = false)
              : (this.openNotepad = true)
            " style="cursor: pointer; font-size: 1.8rem; margin-left: 5px" @contextmenu="onNotepadMenuRightClick"
            ref="notepad-button"></i>
          <div>
            <ContextMenu @mouseenter="mouseEnterContextMenu" @mouseleave="mouseLeaveContextMenu" ref="context-menu"
              id="context-menu" :model="notepadMenuItems" />
          </div>
        </template>
      </ExpandMenu>
      <div class="lala" ref="lala" id="lala" name="lala"></div>

      <!-- Fim notepad -->

      <Menu ref="menu" id="overlay_menu" :model="usersToAssign" :popup="true"
        style="max-height: 250px; overflow-y: auto;" />

      <div class="col-12">
        <div style="padding: 40px 0px 24px 0px">
          <SpeedDial v-if="incidentDataLocal != undefined && editIncidentButton == false && reviewIncidentButton == false"
            style="right: 20px; bottom: 20px; position: fixed;" :model="dialItems" :radius="120" type="quarter-circle"
            direction="up-left" :tooltipOptions="{ position: 'top' }">
            <template #item="{ item }">
              <div v-tooltip.top="{ value: item.label }">
                <Button :icon="item.icon" @click="item.command"
                  class="p-button p-component p-button-icon-only p-button-rounded" :disabled="item.disabled" />
              </div>
            </template>
          </SpeedDial>
          <div>

            <Toast />
            <Dialog class="statusIncidentDialog" :showHeader="false" v-model:visible="displayModalTweet"
              style="width: 30vw" :modal="true" :dismissable-mask="true" @hide="clickedAtReview = false">
              <Vue3Lottie ref="lottieContainer2" :animationData="thunderAnimation" :height="100" :width="100" />
              <div class="m-3">
                <b>{{ this.$langGetValue('generatedTweet') }}:</b>
                <Textarea class="mt-2" v-model="this.editableTweet" rows="5" cols="30" />
              </div>
              <template #footer>
                <div class="text-center mt-2">
                  <Button icon="pi pi-cog" :label="this.$langGetValue('buttonGenerate')"
                    class="p-button p-component p-button-warning" @click="regenerateTweet"
                    :disabled="tweetGenerateButton" />
                  <Button icon="pi pi-check" :label="this.$langGetValue('buttonAccept')"
                    class="p-button p-component p-button-success" @click="acceptEditableTweet"
                    :disabled="tweetReplaceButton" />
                </div>
              </template>
            </Dialog>


            <Dialog class="statusIncidentDialog" :showHeader="false" v-model:visible="displayModalReject"
              style="width: 20vw" :modal="true" :dismissable-mask="true">
              <div class="pt-3 flex flex-column ">
                <div class="flex justify-content-center">
                  <img src="images/logo_only_cloud_storm_black_font.png" style="width: 50px; height:50px">
                </div>
                <div class="mt-2 flex justify-content-center">
                  <b style="font-size: 1.05rem">{{ this.$langGetValue('confirmRefuse') }}</b>
                </div>
              </div>
              <template #footer>
                <div class="text-center mt-2">
                  <Button icon="pi pi-times" :label="this.$langGetValue('buttonNo')" class="p-button p-component"
                    @click="displayModalReject = false" />
                  <Button icon="pi pi-check" :label="this.$langGetValue('buttonYes')" class="p-button p-component"
                    @click="deleteIncident" />
                </div>
              </template>
            </Dialog>


            <Dialog class="statusIncidentDialog" :showHeader="false" v-model:visible="displayModalIncident"
              style="width: 30vw" :modal="true">
              <Vue3Lottie ref="lottieContainer2" :animationData="thunderAnimation" :height="100" :width="100" />
              <div class="mt-3">
                <h5 style="margin-left: 20px; font-weight: bold;">{{ this.$langGetValue("dialog0") }}</h5>
                <p style="margin-left: 20px">{{ this.$langGetValue("dialog1") }} {{ statusIncident }}</p>
                <p style="margin-left: 20px">{{ incidenteLabel }}</p>
              </div>
              <template #footer>
                <Button v-if="((resultRisk.risk_level != 'low' && incidentVariables.riskLevel == null || incidentVariables.riskLevel != null && incidentVariables.riskLevel.risk != 'low')
                  || this.incidentDataLocal?.status == 2
                  || this.incidentDataLocal?.status == 3)
                  && checkRole(['team.op-risk'])
                  && (statusIncident != this.$langGetValue('statusReviewed') && incidentDataLocal?.status != 4)"
                  :label="this.$langGetValue('buttonReview')" @click="loadIncidentsMode(registeredID, 'review')"
                  style="width: 90px" />
                <Button v-if="this.salesforce == false" :label="this.$langGetValue('buttonClose')"
                  @click="this.statusIncident == this.$langGetValue('statusCreated') ? this.$router.push({ path: '/incident/search?', query: { id: registeredID } }) : reloadSearchedIncidentPage();"
                  style="width: 90px" />
                <Button v-else :label="this.$langGetValue('buttonClose')" @click="redirectSalesforceIncident()"
                  style="width: 90px" />
              </template>
            </Dialog>

            <div v-if="this.incidentDataLocal != undefined" class="flex justify-content-between" style="height: 30px">
              <div class="flex flex-column">
                <div class="flex flex-column">
                  <div class='flex text-600	' style="font-size: 12px">
                    {{ this.$langGetValue('dateOfRegistration') }}
                  </div>
                  <div class="flex text-900 font-medium text-lg align-items-center mt-1">
                    <i class="pi pi-calendar mr-1"></i> {{ formatDate(incidentDataLocal.submittedTimestamp) }}
                  </div>
                </div>
                <div class="flex flex-column mt-3">
                  <div class='flex text-600	' style="font-size: 12px">
                    {{ this.$langGetValue('dateOfUpdate') }}
                  </div>
                  <div class="flex text-900 font-medium text-lg	align-items-center mt-1">
                    <i class="pi pi-calendar mr-1"></i> {{ formatDate(incidentDataLocal.updatedTimestamp) }}
                  </div>
                </div>
              </div>
              <div v-if="checkRole(['team.op-risk'])">
                <Tag
                  v-if="checkRole(['inc.distribution']) && (this.incidentDataLocal.status == 1 || this.incidentDataLocal.status == 2 || this.incidentDataLocal.status == 3)"
                  @click="assignIncident()" :value="getStatus(incidentDataLocal.status)"
                  :severity="getStatusSeverity(incidentDataLocal.status)"
                  style="color: black; font-size: 0.9rem; cursor: pointer"></Tag>
                <Tag v-else-if="(this.incidentDataLocal.status == 1 || this.incidentDataLocal.status == 2)"
                  @click="assignIncidentToMe()" :value="getStatus(incidentDataLocal.status)"
                  :severity="getStatusSeverity(incidentDataLocal.status)"
                  style="color: black; font-size: 0.9rem; cursor: pointer"></Tag>
                <Tag v-else :value="getStatus(incidentDataLocal.status)"
                  :severity="getStatusSeverity(incidentDataLocal.status)" style="color: black; font-size: 0.9rem;"></Tag>
              </div>
            </div>
            <div class="flex flex-column justify-content-center text-center">
              <h2 class="h2" :style="{ 'margin-bottom': salesforce ? '0px' : '' }">
                <!-- Incidente -->
                {{ this.$langGetValue("incident") }}
              </h2>
              <div v-if="this.salesforce">
                <img src="images/salesforce.png" style="width: fit-content; height:60px">
              </div>
              <div class="flex justify-content-center align-content-center">
                <div class="flex">
                  <Skeleton v-if="this.incidentRisk != null"
                    v-tooltip.top="{ value: getMarioSeverity(this.incidentRisk) }" shape="circle" size="1rem"
                    class="mr-2 mt-1" :style="{
                      'background-color': getMarioSeverityColor(this.incidentRisk),
                    }">
                  </Skeleton>
                </div>
                <div class="flex">
                  <h5>{{ incidenteLabel }}</h5>
                </div>
              </div>
              <div class="flex justify-content-center"
                v-if="this.incidentDataLocal !== undefined && (this.incidentDataLocal.tweet !== null || this.incidentDataLocal.tweetBrainSTORM !== null)">
                <div style="
                    font-size: 0.8em;
                  ">
                  {{ this.incidentVariables.tweetValue !== null ? this.incidentVariables.tweetValue :
                    this.tweetBrainSTORM }}
                </div>
                <i v-if="this.incidentModeLocal == 'edit' || this.incidentModeLocal == 'review'"
                  v-tooltip.top="{ value: this.$langGetValue('editTweet') }"
                  style="font-size: 1.1rem; cursor: pointer; color: rgb(73, 80, 87)" @click="openModalTweet"
                  class="pi pi-pencil ml-2"></i>
              </div>
            </div>
            <div style="height: 50px"></div>

            <div class="dates flex flex-row justify-content-between" style="width: 100%">
              <div class="date-ocurrence" style="width: 45%">

                <div class="flex flex-row flex-wrap">
                  <h5>
                    <i style="color: brown; cursor: help" title="Campo Obrigatório">*</i>
                    <!-- Data de Ocorrência -->
                    {{ this.$langGetValue("dateOfOccurrence") }}
                  </h5>
                </div>
                <div style="
                  margin-left: 10px;
                  font-size: 0.8em;
                  margin-top: -10px;
                  margin-bottom: 10px;
                ">
                  <!-- Em qual data ocorreu o incidente? -->
                  {{ this.$langGetValue("dateOfOccurrenceSub") }}
                </div>
                <div>
                  <Calendar autocomplete="off" :disabled="blockComponent" style="width: 100%"
                    :class="{ 'p-invalid': isInvalid.dateOccurrence }" placeholder="dd/mm/yyyy" dateFormat="dd/mm/yy"
                    id="minmax" v-model="incidentVariables.dateOccurrence" :maxDate="new Date()" :manualInput="true"
                    ref="dateOccurrence" />
                </div>

              </div>

              <div class="date-discovery" style="width: 45%">
                <div class="flex flex-row flex-wrap">
                  <h5>
                    <i style="color: brown; cursor: help" title="Campo Obrigatório">*</i>
                    <!-- Data de Descoberta -->
                    {{ this.$langGetValue("dateOfDiscovery") }}
                  </h5>
                </div>
                <div style="
                  margin-left: 10px;
                  font-size: 0.8em;
                  margin-top: -10px;
                  margin-bottom: 10px;
                ">
                  <!-- Em qual data o evento foi descoberto? -->
                  {{ this.$langGetValue("dateOfDiscoverySub") }}
                </div>
                <div>
                  <Calendar autocomplete="off" :disabled="blockComponent" style="width: 100%"
                    :class="{ 'p-invalid': isInvalid.dateDiscovery }" placeholder="dd/mm/yyyy" dateFormat="dd/mm/yy"
                    id="minmax" v-model="incidentVariables.dateDiscovery" :maxDate="new Date()"
                    :minDate="incidentVariables.dateOccurrence" :manualInput="true" ref="dateDiscovery" />
                </div>
              </div>
            </div>

            <div style="height: 50px"></div>
            <div class="description" @mouseenter="mouseInDescription = true" @mouseleave="mouseInDescription = false">
              <div class="flex flex-row flex-wrap">
                <h5>
                  <i style="color: brown; cursor: help" title="Campo Obrigatório">*</i>
                  <!-- Descrição -->
                  {{ this.$langGetValue("description") }}
                </h5>
                <i v-if="incidentDataLocal != undefined" v-tooltip.top="{ value: 'Copiar descrição' }"
                  style="font-size: 1.1rem; cursor: pointer; color: rgb(73, 80, 87)" @click="copyDescription()"
                  class="pi pi-copy mt-1 ml-1"></i>
              </div>

              <!-- Relate o incidente dando destaque na descrição dos impactos, na causa do problema ocorrido, como foi
              descoberto, e que ações foram executadas para resolução. <br>
              <b>O campo de Descrição aceita imagem.</b> Você pode colar (CTRL + V) prints abaixo. <b>(O campo deve ter no
                mínimo 100 caracteres)</b> -->

              <div ref="description" style="margin-left: 10px; font-size: 0.8em; margin-top: -10px"
                v-html='this.$langGetValue("descriptionSub")'>
              </div>


              <br />
              <div>
                <div class="bodyDescription" id="editor-container">
                  <p></p>
                </div>
              </div>

              <br />

            </div>
            <div v-if="checkRole(['team.op-risk']) && this.incidentDataLocal != undefined">
              <div style="height: 20px"></div>
              <div class="reviewedDescription flex flex-column" style="gap:5px">
                <h5>
                  {{ this.$langGetValue("reviewedDescription") }}
                </h5>
                <div ref="description" style="margin-left: 10px; font-size: 0.8em; margin-top: -10px"
                  v-html='this.$langGetValue("reviewedDescriptionSublabel")'>
                </div>
                <Textarea :disabled="blockComponent" style="width:100%" v-model="incidentVariables.reviewedDescription" rows="5"/>
              </div>
            </div>

            <div class="hashtags flex flex-row flex-wrap justify-content-between" style="width: 100%">
              <div class="hashtags flex flex-column" style="width: 45%">
                <div style="height: 20px"></div>

                <div v-if="incidentVariables.hashtagSuggestion == null || incidentVariables.hashtagSuggestion.length == 0
                  ">
                  <h5>
                    <i style="color: brown; cursor: help" title="Campo Obrigatório">*</i>
                    <!-- Hashtags -->
                    {{ this.$langGetValue("hashtag") }}
                  </h5>

                  <div style="
                  margin-left: 10px;
                  font-size: 0.8em;
                  margin-top: -10px;
                  margin-bottom: 10px;
                ">
                    <!-- Inclua aqui, em formato de #, palavras-chaves -->
                    {{ this.$langGetValue("hashtagSub") }}
                  </div>
                </div>

                <div v-else>
                  <h5>
                    <!-- Hashtags -->
                    {{ this.$langGetValue("hashtag") }}
                  </h5>
                  <div style="
                  margin-left: 10px;
                  font-size: 0.8em;
                  margin-top: -10px;
                  margin-bottom: 10px;
                ">
                    <!-- Inclua aqui, em formato de #, palavras-chaves -->
                    {{ this.$langGetValue("hashtagSub") }}
                  </div>
                </div>


                <div class="p-fluid">
                  <AutoComplete :disabled="blockComponent" :class="{ 'p-invalid': isInvalid.selectedHashtags }"
                    ref="selectedHashtags" :multiple="true" v-model="incidentVariables.selectedHashtags"
                    :suggestions="filteredHashtags" @complete="searchHashtags($event)" :forceSelection="true"
                    field="label" />
                </div>
              </div>

              <div class="hashtags-suggestion" style="width: 45%">
                <div style="height: 20px"></div>

                <div class="flex flex-row flex-wrap"
                  v-if="incidentVariables.hashtagSuggestion != null && incidentVariables.hashtagSuggestion.length > 0">
                  <h5>
                    <i style="color: brown; cursor: help" title="Campo Obrigatório">*</i>
                    <!-- Sugestões de Hashtags -->
                    {{ this.$langGetValue("hashtagSuggestion") }}
                  </h5>
                </div>

                <div class="flex flex-row flex-wrap" v-else>
                  <h5>
                    <!-- Sugestões de Hashtags -->
                    {{ this.$langGetValue("hashtagSuggestion") }}
                  </h5>
                </div>

                <div style="
                  margin-left: 10px;
                  font-size: 0.8em;
                  margin-top: -10px;
                  margin-bottom: 10px;
                ">
                  <!-- Não encontrou a hashtag que gostaria? Faça sua sugestão para
                avaliarmos a inclusão da #hashtag em nossa relação. -->
                  {{ this.$langGetValue("hashtagSuggestionSub") }}
                </div>
                <div class="p-fluid">
                  <Chips :disabled="blockComponent" ref="hashtagSuggestion"
                    :class="{ 'p-invalid': isInvalid.selectedHashtags }" v-model="incidentVariables.hashtagSuggestion"
                    separator="," :addOnBlur="true" />
                </div>
              </div>
            </div>

            <div style="height: 50px"></div>
            <div class="basel-level">
              <h5>
                <i style="color: brown; cursor: help" title="Campo Obrigatório">*</i>
                <!-- Nível de Basiléia -->
                {{ this.$langGetValue("basel") }}
              </h5>
              <div style="
                margin-left: 10px;
                font-size: 0.8em;
                margin-top: -10px;
                margin-bottom: 10px;
              ">
                <!-- Qual é o tipo de falha operacional em que o incidente se enquadra
              de acordo com os níveis de Basileia? -->
                {{ this.$langGetValue("baselSub") }}
              </div>
              <div v-if="blockComponent" class="flex align-items-center">
                <Chip :label="viewBaselOne" class="secondaryChip" />
                <i class="pi pi-angle-right" style="font-size: 1rem; padding: 0px 2px"></i>
                <Chip :label="viewBaselTwo" class="secondaryChip" />
              </div>
              <Listbox v-else :class="{ 'p-invalid': isInvalid.selectedBasel }" v-model="incidentVariables.selectedBasel"
                :options="basel" optionLabel="label"
                :optionGroupLabel="option => option.data.i18n[this.language] !== undefined ? option.data.i18n[this.language] : option.label"
                optionGroupChildren="children" listStyle="max-height:250px" ref="selectedBasel">
                <template #option="slotProps">
                  <div class="">
                    <div style="margin-left: 15px">
                      {{ slotProps.option.data.i18n && slotProps.option.data.i18n[this.language] !== undefined ?
                        slotProps.option.data.i18n[this.language] : slotProps.option.label }}
                    </div>
                  </div>
                </template>
              </Listbox>
            </div>
            <div style="height: 50px"></div>
            <div class="rf-system flex flex-row flex-wrap justify-content-between" style="width: 100%">
              <div class="risk-factor" style="width: 45%">
                <div class="flex flex-row flex-wrap">
                  <h5>
                    <i style="color: brown; cursor: help" title="Campo Obrigatório">*</i>
                    Risk Factor
                  </h5>
                </div>
                <div style="
                  margin-left: 10px;
                  font-size: 0.8em;
                  margin-top: -10px;
                  margin-bottom: 10px;
                ">
                  <!-- Qual foi o fator que originou o incidente? -->
                  {{ this.$langGetValue("riskFactorSub") }}
                </div>
                <Dropdown :disabled="blockComponent" :class="{ 'p-invalid': isInvalid.selectedRiskFactor }"
                  v-model="incidentVariables.selectedRiskFactor" :options="riskFactor" optionLabel="label"
                  placeholder="Select" ref="selectedRiskFactor" />
              </div>

              <div class="system" style="width: 45%">
                <div class="flex flex-row flex-wrap" v-if="checkSystem()">
                  <h5>
                    <i style="color: brown; cursor: help" title="Campo Obrigatório">*</i>
                    <!-- Sistemas -->
                    {{ this.$langGetValue("system") }}
                  </h5>
                </div>

                <div class="flex flex-row flex-wrap" v-else>
                  <h5>
                    <!-- Sistemas -->
                    {{ this.$langGetValue("system") }}
                  </h5>
                </div>

                <div style="
                  margin-left: 10px;
                  font-size: 0.8em;
                  margin-top: -10px;
                  margin-bottom: 10px;
                ">
                  <!-- Quais os sistemas envolvidos no incidente? -->
                  {{ this.$langGetValue("systemSub") }}
                </div>
                <MultiSelect :disabled="blockComponent" :class="{ 'p-invalid': isInvalid.selectedSystem }"
                  v-model="incidentVariables.selectedSystem" :options="system" optionLabel="label" placeholder="Select"
                  :filter="true" ref="selectedSystem" />
              </div>
            </div>
            <div style="height: 50px"></div>

            <div class="product-capability flex flex-row flex-wrap justify-content-between" style="width: 100%">
              <div class="product" style="width: 45%">
                <div class="flex flex-row flex-wrap">
                  <h5>
                    <i style="color: brown; cursor: help" title="Campo Obrigatório">*</i>
                    <!-- Produtos -->
                    {{ this.$langGetValue("products") }}
                  </h5>
                </div>

                <div style="
                  margin-left: 10px;
                  font-size: 0.8em;
                  margin-top: -10px;
                  margin-bottom: 10px;
                ">
                  <!-- Qual esteira de produtos ou serviços foi impactada? -->
                  {{ this.$langGetValue("productsSub") }}
                </div>
                <MultiSelect :disabled="blockComponent" style="width: 100%"
                  :class="{ 'p-invalid': isInvalid.selectedProduct }" v-model="incidentVariables.selectedProduct"
                  :options="product" optionLabel="label" placeholder="Select" :filter="true" ref="selectedProduct" />
              </div>

              <div class="capability" style="width: 45%">
                <div class="flex flex-row flex-wrap">
                  <h5>
                    <i style="color: brown; cursor: help" title="Campo Obrigatório">*</i>
                    Capability
                  </h5>
                </div>
                <div style="
                  margin-left: 10px;
                  font-size: 0.8em;
                  margin-top: -10px;
                  margin-bottom: 10px;
                ">
                  <!-- Escolha o macro processo relacionado -->
                  {{ this.$langGetValue("capabilitySub") }}
                </div>
                <Dropdown filter :disabled="blockComponent" :class="{ 'p-invalid': isInvalid.selectedCapability }"
                  panelClass="capability-custom-dropdown" v-model="incidentVariables.selectedCapability"
                  :options="capabilities" optionLabel="label" placeholder="Select" ref="selectedCapability">
                  <template #option="slotProps">
                    <div style="padding: 10.5px 17.5px 10.5px 17.5px" class="flex align-items-center" v-tooltip.top="{
                      value: slotProps.option.description,
                      class: 'edited-tooltip-incident',
                    }">
                      {{ slotProps.option.label }}
                    </div>
                  </template>
                </Dropdown>
              </div>
            </div>
            <div style="height: 50px"></div>

            <div class="company" style="width: 45%">
              <div class="flex flex-row flex-wrap">
                <h5>
                  <i style="color: brown; cursor: help" title="Campo Obrigatório">*</i>
                  <!-- Empresa -->
                  {{ this.$langGetValue("company") }}
                </h5>
              </div>
              <div style="
                margin-left: 10px;
                font-size: 0.8em;
                margin-top: -10px;
                margin-bottom: 10px;
              ">
                <!-- Qual entidade do BTG foi impactada pelo incidente? -->
                {{ this.$langGetValue("companySub") }}
              </div>
              <Dropdown :disabled="blockComponent" :class="{ 'p-invalid': isInvalid.selectedCompany }"
                v-model="incidentVariables.selectedCompany" :options="company" optionLabel="label"
                optionGroupLabel="label" optionGroupChildren="children" placeholder="Select" :filter="true"
                ref="selectedCompany" />
            </div>

            <div style="width: 100%;" class="flex justify-content-between">
              <div style="width: 48%" class="originated-business-unit" v-show="checkOBU()">
                <div style="height: 50px"></div>
                <div class="flex flex-row flex-wrap">
                  <h5>
                    <i style="color: brown; cursor: help" title="Campo Obrigatório">*</i>
                    <!-- Área que originou -->
                    {{ this.$langGetValue("originatedArea") }}
                  </h5>
                </div>
                <div style="
                  margin-left: 10px;
                  font-size: 0.8em;
                  margin-top: -10px;
                  margin-bottom: 10px;
                ">
                  <!-- Qual foi a área que originou o incidente? -->
                  {{ this.$langGetValue("originatedAreaSub") }}
                </div>
                <div>
                  <div v-if="blockComponent == false && incidentDataLocal != undefined && selectedOBUChip != null" style="
                  font-size: 0.8em;
                  padding: 0px 0px 8px 0px;
                ">
                    <b>Anterior:</b>
                  </div>
                  <Chip v-if="Object.keys(viewOBUChip).length != 0" :label="viewOBUChip.label" v-tooltip.top="{
                    value: viewOBUChip.path,
                    class: 'edited-tooltip-incident',
                  }" class="customChip" />
                  <div v-if="blockComponent == false && incidentDataLocal != undefined && selectedOBUChip != null" style="
                  font-size: 0.8em;
                  padding: 12px 0px 8px 0px
                ">
                    <b>Modificado:</b>
                  </div>
                </div>
                <div style="margin: 5px;" v-if="blockComponent == false && selectedOBUChip != null">
                  <Chip :label="selectedOBUChip" class="customChipInSelection" />
                </div>
                <Tree v-if="blockComponent == false" id="obu-tree" style="margin: 16px 0px 0px 0px"
                  :class="{ 'p-invalid': isInvalid.selectedOBU, 'customTreeHierarchy': true }"
                  v-model:selectionKeys="incidentVariables.selectedOBU" :value="hierarchyOBU"
                  :expanded-keys="expandedKeysOBU" scrollHeight="430px" selectionMode="single" :filter="true"
                  filterMode="lenient" @node-select="chipSelectionOBU" ref="selectedOBU" />
              </div>

              <div :style="{ width: checkOBU() ? '48%' : '100%' }" class="impacted-business-units">
                <div style="height: 50px"></div>
                <div class="flex flex-row flex-wrap">
                  <h5>
                    <i style="color: brown; cursor: help" title="Campo Obrigatório">*</i>
                    <!-- Áreas Impactadas -->
                    {{ this.$langGetValue("impactedAreas") }}
                  </h5>
                </div>

                <div style="
                  margin-left: 10px;
                  font-size: 0.8em;
                  margin-top: -10px;
                  margin-bottom: 10px;
                ">
                  <!-- Quais foram as áreas impactadas pelo incidente? -->
                  {{ this.$langGetValue("impactedAreasSub") }}
                </div>
                <div style="margin: 5px">
                  <div>
                    <div v-if="blockComponent == false && incidentDataLocal != undefined && selectedIBUsChip.length > 0"
                      style="
                        font-size: 0.8em;
                        padding: 0px 0px 8px 0px;
                      ">
                      <b>Anterior:</b>
                    </div>
                    <Chip v-for="selected in viewIBUsChip" style="margin-left: 3px" :key="selected"
                      :label="selected.label" v-tooltip.top="{
                        value: selected.path,
                        class: 'edited-tooltip-incident',
                      }" class="customChip" />

                    <div v-if="blockComponent == false && incidentDataLocal != undefined && selectedIBUsChip.length > 0"
                      style="
                  font-size: 0.8em;
                  padding: 12px 0px 8px 0px
                ">
                      <b>Modificado:</b>
                    </div>
                  </div>
                  <div class="flex align-items-center" v-if="blockComponent == false && selectedIBUsChip.length > 0">
                    <Chip icon="pi pi-trash" v-if="selectedIBUsChip.length > 0" style="
                        background-color: transparent;
                        color: #eb3d47;
                        padding: 0px;
                        font-weight: 600;
                        cursor: pointer;
                      " label="LIMPAR" @click="removeAllIBUs" />
                    <Divider layout="vertical" v-if="selectedIBUsChip.length > 0" style="
                        padding-left: 8px;
                        padding-right: 8px;
                        height: 100%;
                      " />
                    <div class="flex flex-wrap" style="width: 100%; gap: 4px">
                      <Chip v-for="selected in selectedIBUsChip" class="customChipInSelection" :key="selected"
                        :label="selected.label" removable @remove="chipRemoveIBUs(selected)" />
                    </div>
                  </div>
                </div>
                <Tree v-if="blockComponent == false" id="ibus-tree" style="margin: 16px 0px 0px 0px"
                  :class="{ 'p-invalid': isInvalid.selectedIBUs, 'customTreeHierarchy': true }"
                  v-model:selectionKeys="incidentVariables.selectedIBUs" :metaKeySelection="false"
                  :expanded-keys="expandedKeysIBUs" :value="hierarchyIBUs" scrollHeight="430px" selectionMode="multiple"
                  :filter="true" filterMode="lenient" @node-select="chipSelectionIBUs"
                  @node-unselect="chipUnselectionIBUs" ref="selectedIBUs" />
              </div>
            </div>

            <div style="margin-top: 4%" class="impact-mario" ref="impact-mario">
              <div class="flex flex-row flex-wrap">
                <h5>
                  <i style="color: brown; cursor: help" title="Campo Obrigatório">*</i>
                  <!-- Impactos -->
                  {{ this.$langGetValue("impacts") }}
                </h5>
              </div>
              <div style="
                margin-left: 10px;
                font-size: 0.8em;
                margin-top: -10px;
                margin-bottom: 10px;
              ">
                <!-- Responda as perguntas sobre os impactos do incidente -->
                {{ this.$langGetValue("impactsSub") }}
              </div>

              <TabView v-model:activeIndex="currentIndexTab" style="border-radius: 6px" id="tabMario">
                <TabPanel>
                  <template #header>
                    <i class="pi pi-cog mr-2"></i>
                    <span>{{ this.$langGetValue("op") }}</span>
                  </template>
                  <Accordion v-model:activeIndex="currentIndexOpe" :multiple="true">
                    <AccordionTab :header="this.$langGetValue('op01')">
                      <div class="flex align-items-center mt-1" v-for="option in ope01" :key="option">
                        <RadioButton :disabled="blockComponent" v-model="incidentVariables.ope01_selected"
                          :disable="blockComponent" :value="option.id" @click="clearOpRisk" />
                        <label class="ml-2">{{ option.data.i18n && option.data.i18n[this.language] !== undefined ?
                          option.data.i18n[this.language] : option.label }}</label>
                      </div>
                    </AccordionTab>
                    <AccordionTab :disabled="disableOp02" :header="this.$langGetValue('op02')">
                      <div class="flex align-items-center mt-1" v-for="option in ope02" :key="option">
                        <RadioButton :disabled="blockComponent" v-model="incidentVariables.ope02_selected"
                          :disable="blockComponent" :value="option.id" @click="valuateOpRisk(true)" />
                        <label class="ml-2">{{ option.data.i18n && option.data.i18n[this.language] !== undefined ?
                          option.data.i18n[this.language] : option.label }}</label>
                      </div>
                    </AccordionTab>
                  </Accordion>
                </TabPanel>
                <TabPanel>
                  <template #header>
                    <i class="pi pi-book mr-2"></i>
                    <span>{{ this.$langGetValue("reg") }}</span>
                  </template>
                  <Accordion v-model:activeIndex="currentIndexReg" :multiple="true">
                    <AccordionTab :header="this.$langGetValue('reg01')">
                      <div class="flex align-items-center mt-1" v-for="option in reg01" :key="option">
                        <RadioButton :disabled="blockComponent" v-model="incidentVariables.reg01_selected"
                          :value="option.id" @click="valuateRegRisk(true)" />
                        <label class="ml-2">{{ option.data.i18n && option.data.i18n[this.language] !== undefined ?
                          option.data.i18n[this.language] : option.label }}</label>
                      </div>
                    </AccordionTab>
                  </Accordion>
                </TabPanel>
                <TabPanel>
                  <template #header>
                    <i class="pi pi-comment mr-2"></i>
                    <span>{{ this.$langGetValue("rep") }}</span>
                  </template>
                  <Accordion v-model:activeIndex="currentIndexRep" :multiple="true">
                    <AccordionTab :header="this.$langGetValue('rep01')">
                      <div class="flex align-items-center mt-1" v-for="option in rep01" :key="option">
                        <RadioButton :disabled="blockComponent" v-model="incidentVariables.rep01_selected"
                          :value="option.id" @click="clearRepRisk" />
                        <label class="ml-2">{{ option.data.i18n && option.data.i18n[this.language] !== undefined ?
                          option.data.i18n[this.language] : option.label }}</label>
                      </div>
                      <div class="flex align-items-center mt-1">
                        <RadioButton :disabled="blockComponent" v-model="incidentVariables.rep01_selected" value="input"
                          @click="clearRepRisk" />
                        <label class="ml-2">{{ this.$langGetValue("rep01Input") }}</label>
                      </div>
                      <div class="flex align-items-center mt-3">
                        <InputNumber :disabled="blockComponent" v-model="incidentVariables.rep01_value"
                          :useGrouping="false"
                          v-tooltip.top="{ value: this.$langGetValue('marioInputToolTip'), class: 'edited-tooltip-incident' }"
                          @blur="clearRepRisk" @click="clearRepRisk(); incidentVariables.rep01_selected = 'input'" />
                      </div>
                    </AccordionTab>
                    <AccordionTab :disabled="disableRep02" :header="this.$langGetValue('rep02')">
                      <div class="flex align-items-center mt-1" v-for="option in rep02" :key="option">
                        <RadioButton :disabled="blockComponent" v-model="incidentVariables.rep02_selected"
                          :value="option.id" @click="valuateRepRisk(true)" />
                        <label class="ml-2">{{ option.data.i18n && option.data.i18n[this.language] !== undefined ?
                          option.data.i18n[this.language] : option.label }}</label>
                      </div>
                    </AccordionTab>
                  </Accordion>
                </TabPanel>
                <TabPanel>
                  <template #header>
                    <i class="pi pi-dollar mr-2"></i>
                    <span>{{ this.$langGetValue("fin") }}</span>
                  </template>
                  <Accordion v-model:activeIndex="currentIndexFin" :multiple="true">
                    <AccordionTab :header="this.$langGetValue('fin01')">
                      <div class="flex align-items-center mt-1" v-for="option in fin01" :key="option">
                        <RadioButton :disabled="blockComponent" v-model="incidentVariables.fin01_selected"
                          :value="option.id" @click="clearFinRisk" />
                        <label class="ml-2">{{ option.data.i18n && option.data.i18n[this.language] !== undefined ?
                          option.data.i18n[this.language] : option.label }}</label>
                      </div>
                    </AccordionTab>
                    <AccordionTab v-if="incidentVariables.fin01_selected == 4" :header="this.$langGetValue('fin02')">
                      <div class="flex align-items-center mt-1" v-for="option in fin02" :key="option">
                        <RadioButton :disabled="blockComponent" v-model="incidentVariables.fin02_selected"
                          :value="option.id" @click="verifyFin02" />
                        <label class="ml-2">{{ option.data.i18n && option.data.i18n[this.language] !== undefined ?
                          option.data.i18n[this.language] : option.label }}</label>
                      </div>
                    </AccordionTab>
                    <AccordionTab v-if="incidentVariables.fin02_selected != 100 && incidentVariables.fin02_selected != null ||
                      (incidentVariables.fin01_selected != 4 && incidentVariables.fin01_selected != null)"
                      :header="this.$langGetValue('fin03')">
                      <div class="flex align-items-center mt-2">
                        <div>
                          <Dropdown
                            style="padding: 0; border-left: none; border-right: none; border-top: none; border-radius: 0px;"
                            :disabled="blockComponent" v-model="fin03_currency" :options="fin03" optionLabel="label" />
                        </div>
                        <div>
                          <InputNumber :disabled="blockComponent" v-model="incidentVariables.fin03_selected"
                            placeholder="0.00" :useGrouping="true" mode="currency" :currency="fin03_currency.label"
                            @blur="verifyFin03"
                            v-tooltip.top="{ value: this.$langGetValue('marioInputToolTip'), class: 'edited-tooltip-incident' }" />
                        </div>
                      </div>
                    </AccordionTab>
                    <AccordionTab v-if="incidentVariables.fin01_selected == 1 || incidentVariables.fin01_selected == 3"
                      :header="this.$langGetValue('fin04')">
                      <div class="flex align-items-center mt-1" v-for="option in fin04" :key="option">
                        <RadioButton :disabled="blockComponent" v-model="incidentVariables.fin04_selected"
                          :value="option.id" @click="valuateFinRisk" />
                        <label class="ml-2">{{ option.data.i18n && option.data.i18n[this.language] !== undefined ?
                          option.data.i18n[this.language] : option.label }}</label>
                      </div>
                    </AccordionTab>
                  </Accordion>
                </TabPanel>
              </TabView>

              <div v-if="checkRole(['team.op-risk'])" class="flex justify-content-evenly" style="width: 100%">
                <div class="text-center">
                  <Knob v-model="opRiskValue" :size="80" readonly />
                  <div>
                    <Avatar icon="pi pi-cog" shape="circle" />
                    <h6 style="margin-top: 3px">{{ this.$langGetValue("op") }}</h6>
                  </div>
                </div>

                <div class="text-center">
                  <Knob v-model="regRiskValue" :size="80" readonly />
                  <div>
                    <Avatar icon="pi pi-book" shape="circle" />
                    <h6 style="margin-top: 3px">{{ this.$langGetValue("reg") }}</h6>
                  </div>
                </div>

                <div class="text-center">
                  <Knob v-model="repRiskValue" :max="maxRep" :size="80" readonly />
                  <div>
                    <Avatar icon="pi pi-comment" shape="circle" />
                    <h6 style="margin-top: 3px">{{ this.$langGetValue("rep") }}</h6>
                  </div>
                </div>

                <div class="text-center">
                  <Knob v-model="finRiskValue" :max="maxFin" :size="80" readonly />
                  <div>
                    <Avatar icon="pi pi-dollar" shape="circle" />
                    <h6 style="margin-top: 3px">{{ this.$langGetValue("fin") }}</h6>
                  </div>
                </div>

                <div class="text-center">
                  <Knob v-model="totalRiskValue" :max="maxTotal"
                    :valueColor="getMarioSeverityColor(this.resultRisk.risk_level)" :size="80" readonly />
                  <div>
                    <Avatar icon="pi pi-chart-line" shape="circle" />
                    <h6 style="margin-top: 3px">{{ resultRisk.risk_level != null ? getMarioSeverity(resultRisk.risk_level)
                      :
                      this.$langGetValue("marioStatus") }}</h6>
                  </div>
                </div>

                <div
                  v-if="(totalRiskValue != 0 && incidentDataLocal != undefined && incidentDataLocal.reasonForNewRiskLevel != null) || (totalRiskValue != 0 && blockComponent == false)"
                  class="flex justify-content-start align-items-center">
                  <div class="p-float-label">
                    <Dropdown v-model="incidentVariables.riskLevel" showClear :options="riskOptions"
                      :disabled="blockComponent">
                      <template #value="slotProps">
                        <div v-if="slotProps.value" class="flex align-items-center">
                          <Skeleton v-tooltip.top="{ value: getMarioSeverity(slotProps.value.risk) }" shape="circle"
                            size="1rem" class="mr-2 mt-1" :style="{
                              'background-color': getMarioSeverityColor(slotProps.value.risk),
                            }">
                          </Skeleton>
                          <div>{{ slotProps.value.label }}</div>
                        </div>
                        <span v-else style="color: white">Forçar Risco</span>
                      </template>
                      <template #option="slotProps">
                        <div class="flex align-items-center">
                          <Skeleton v-tooltip.top="{ value: getMarioSeverity(slotProps.option.risk) }" shape="circle"
                            size="1rem" class="mr-2 mt-1" :style="{
                              'background-color': getMarioSeverityColor(slotProps.option.risk),
                            }">
                          </Skeleton>
                          <div>{{ slotProps.option.label }}</div>
                        </div>
                      </template>
                    </Dropdown>
                    <label>{{ this.$langGetValue("forceRisk") }}</label>
                  </div>
                </div>

                <div v-if="incidentVariables.riskLevel != null" style="width: 30%">
                  <div class="flex flex-row flex-wrap">
                    <h5>
                      <i style="color: brown; cursor: help" title="Campo Obrigatório">*</i>
                      {{ this.$langGetValue("forceRiskJustification") }}
                    </h5>
                  </div>

                  <div style="
                  margin-left: 10px;
                  font-size: 0.8em;
                  margin-top: -10px;
                  margin-bottom: 10px;
                ">
                    {{ this.$langGetValue("forceRiskJustificationSub") }}
                  </div>
                  <div class="p-fluid">
                    <InputText v-tooltip.top="{ value: incidentVariables.riskChangeReason }" ref="riskChangeReason"
                      :disabled="blockComponent" v-model="incidentVariables.riskChangeReason"
                      :class="{ 'p-invalid': isInvalid.riskChangeReason }" />
                  </div>
                </div>
              </div>
            </div>

            <div v-if="checkRole(['team.op-risk'])" style="width: 10%"></div>
            <div
              v-if="checkRole(['team.op-risk']) && (blockComponent == false || incidentVariables.actionPlans.length > 0)"
              class="flex justify-content-between" style="width:100%">

              <div class="actionPlan" style="width: 100%">
                <div style="height: 20px"></div>

                <div class="flex flex-row flex-wrap">
                  <h5>
                    <i v-if="checkRiskIsHighOrCritical()" style="color: brown; cursor: help"
                      title="Campo Obrigatório">*</i>
                    Action Plan
                  </h5>
                </div>

                <!-- <div style="
                  margin-left: 10px;
                  font-size: 0.8em;
                  margin-top: -10px;
                  margin-bottom: 10px;
                ">
                  {{ this.$langGetValue("eventOriginSub") }}
                </div> -->
                <div v-if="showActionPlan" ref="actionPlan">
                  <ActionPlanComponent :query="incidentVariables.actionPlans" :linkWith="'incident'"
                    @update-ap-query="updateQueryAP" :blockComponent="blockComponent" />
                </div>
              </div>

            </div>

            <div v-if="checkRole(['team.op-risk'])" style="width: 10%"></div>
            <div v-if="checkRole(['team.op-risk'])" class="flex justify-content-between" style="width:100%">

              <div class="eventOrigin" style="width: 40%">
                <div style="height: 20px"></div>

                <div class="flex flex-row flex-wrap">
                  <h5>Event Origin</h5>
                </div>

                <div style="
                  margin-left: 10px;
                  font-size: 0.8em;
                  margin-top: -10px;
                  margin-bottom: 10px;
                ">
                  <!-- Incluir evento de origem do incidente -->
                  {{ this.$langGetValue("eventOriginSub") }}
                </div>
                <div class="p-fluid">
                  <InputText class="inputText" :disabled="blockComponent" v-model="incidentVariables.eventOriginValue" />
                </div>
              </div>

              <div v-if="checkRole(['team.op-risk'])" class="accountingDate" style="width: 25%">
                <div style="height: 20px"></div>

                <div class="flex flex-row flex-wrap">
                  <h5>
                    {{ this.$langGetValue("accountingDate") }}
                  </h5>
                </div>
                <div style="
                  margin-left: 10px;
                  font-size: 0.8em;
                  margin-top: -10px;
                  margin-bottom: 10px;
                ">
                  <!-- Em qual data foi contabilizado? -->
                  {{ this.$langGetValue("accountingDateSub") }}
                </div>
                <div class="p-fluid">
                  <Calendar autocomplete="off" :disabled="blockComponent" placeholder="dd/mm/yyyy" dateFormat="dd/mm/yy"
                    :manualInput="true" v-model="incidentVariables.accountingDate" ref="dateDiscovery" />
                </div>
              </div>

              <div v-if="checkRole(['team.op-risk'])" class="managerialImpact" style="width: 25%">
                <div style="height: 20px"></div>

                <div class="flex flex-row flex-wrap">
                  <h5>
                    <!-- Impacto Gerencial -->
                    {{ this.$langGetValue("managerialImpact") }}
                  </h5>
                </div>
                <div style="
                  margin-left: 10px;
                  font-size: 0.8em;
                  margin-top: -10px;
                  margin-bottom: 10px;
                ">
                  <!-- Perda Líquida (R$) -->
                  {{ this.$langGetValue("managerialImpactSub") }}
                </div>
                <div class="p-fluid">
                  <InputNumber :disabled="blockComponent" v-model="incidentVariables.managerialImpact" placeholder="0.00"
                    :useGrouping="true" :minFractionDigits="0" :maxFractionDigits="2" />
                </div>
              </div>

            </div>
            <div v-if="checkRole(['team.op-risk'])" class="flex"
              style="width:100%; justify-content: space-between; margin-top: 30px">
              <div class="eventOrigin" style="width: 30%">
                <div style="height: 20px"></div>

                <div class="flex flex-row flex-wrap">
                  <h5>{{ this.$langGetValue("accountingAccount") }}</h5>
                </div>

                <div style="
                  margin-left: 10px;
                  font-size: 0.8em;
                  margin-top: -10px;
                  margin-bottom: 10px;
                ">
                  <!-- Incluir evento de origem do incidente -->
                  {{ this.$langGetValue("accountingAccountSub") }}
                </div>
                <div class="p-fluid">
                  <InputText class="inputText" :disabled="blockComponent" v-model="incidentVariables.accountingAccount" />
                </div>
              </div>

              <div style="width: 30%">
                <div style="height: 20px"></div>

                <div class="flex flex-row flex-wrap">
                  <h5>{{ this.$langGetValue('relatedIncidents') }}</h5>
                </div>

                <div style="
                  margin-left: 10px;
                  font-size: 0.8em;
                  margin-top: -10px;
                  margin-bottom: 10px;
                ">
                  <!-- Incluir evento de origem do incidente -->
                  {{ this.$langGetValue('includeRelatedIds') }}
                </div>
                <div class="p-fluid">
                  <Chips :disabled="blockComponent" ref="hashtagSuggestion" v-model="incidentVariables.relatedIncident"
                    separator="," :addOnBlur="true" @add="validateRelatedIncident" />
                </div>
              </div>

              <div style="width: 30%">
                <div style="height: 20px"></div>

                <div class="flex flex-row flex-wrap">
                  <h5>{{ this.$langGetValue('relatedIssues') }}</h5>
                </div>

                <div style="
                  margin-left: 10px;
                  font-size: 0.8em;
                  margin-top: -10px;
                  margin-bottom: 10px;
                ">
                  <!-- Incluir evento de origem do incidente -->
                  {{ this.$langGetValue('includeRelatedIdsIssue') }}
                </div>
                <div class="p-fluid">
                  <Chips :disabled="blockComponent" ref="hashtagSuggestion" v-model="incidentVariables.relatedIssue"
                    separator="," :addOnBlur="true" @add="validateRelatedIssue" />
                </div>
              </div>
            </div>

            <div style="height: 50px"></div>
            <div class="flex justify-content-between" style="width: 100%">
              <div style="width: 45%;" class="transfer">
                <div class="has-transfer">
                  <div class="flex flex-row flex-wrap">
                    <h5>
                      <i style="color: brown; cursor: help" title="Campo Obrigatório">*</i>
                      {{ this.$langGetValue("financialTransfer") }}
                    </h5>
                  </div>
                  <div style="
                    margin-left: 10px;
                    font-size: 0.8em;
                    margin-top: -10px;
                    margin-bottom: 10px;
                  " v-html='this.$langGetValue("financialTransferSub")'>
                  </div>
                  <div class="flex">
                    <Dropdown :disabled="blockComponent" :class="{ 'p-invalid': isInvalid.selectedHasTransfer }"
                      v-model="incidentVariables.selectedHasTransfer" :options="hasTransfer" optionLabel="label"
                      placeholder="Select" ref="selectedHasTransfer" />

                    <div class="ml-1">
                      <Button v-if="incidentVariables.selectedHasTransfer == hasTransfer[0]"
                        @click="hasFinancialTransferData = true" class="p-button p-component"
                        :label="this.$langGetValue('financialTransfer')" />
                    </div>
                  </div>
                </div>

                <Dialog :showHeader="false" :dismissable-mask="false" v-model:visible="hasFinancialTransferData" modal>
                  <Card>
                    <template #title>
                      <div class="flex justify-content-start">
                        <div class="flex">
                          {{ this.$langGetValue('financialTransferModalHead') }}
                        </div>
                      </div>
                    </template>

                    <template #subtitle>
                      <div class='flex flex-column'>
                        <div class="mt-4" v-html="this.$langGetValue('financialTransferModalHeadSub')">
                        </div>
                        <Button style="margin-left: 24px; width: 200px" icon="pi pi-download" label="Download template"
                          @click="downloadTransferTemplate" />
                      </div>
                    </template>

                    <template #content>
                      <hr>

                      <div style="width:100%" class="flex align-items-center justify-content-between mb-2">

                        <div>
                          <label style="font-weight: bold;">
                            {{ this.$langGetValue('financialTransferModalApplyCurrency') }}: </label>
                          <Dropdown :disabled="blockComponent" style="border: 1px solid grey"
                            v-model="incidentVariables.selectedTransfersCurrency"
                            :placeholder="this.$langGetValue('financialTransferModalCurrency')"
                            :options="transfersCurrency" optionLabel="label" class="w-full md:w-14rem"
                            @change="fillCurrency" />

                        </div>

                        <div>
                          <label style="font-weight: bold;">
                            {{ this.$langGetValue('financialTransferModalApplyCategory') }}: </label>
                          <Dropdown :disabled="blockComponent" style="border: 1px solid grey"
                            v-model="incidentVariables.selectedTransfersCategory"
                            :placeholder="this.$langGetValue('financialTransferModalCategory')"
                            :options="transferCategory" optionLabel="label" class="w-full md:w-14rem"
                            @change="fillCategory">
                            <template #option="slotProps">
                              <div>
                                {{ slotProps.option.data.i18n && slotProps.option.data.i18n[this.language] !== undefined
                                  ? slotProps.option.data.i18n[this.language] : slotProps.option.label }}
                              </div>
                            </template>
                          </Dropdown>
                        </div>

                        <div>
                          <Button :disabled="blockComponent" style="width: 80px;" class="p-button p-component"
                            @click="pasteDataTableFinancialTransfer" :label="this.$langGetValue('buttonPaste')" />
                        </div>
                      </div>
                      <DataTable ref="refDatatableFinancialTransfer" scrollable scrollHeight="400px"
                        class="card FinancialTransfersDatatable mt-2" :value="dataTableFinancialTransfer">
                        <Column style="min-width: 14rem; justify-content: center;"
                          v-for="col of dataTableFinancialTransferColumns" :key="col.field" :field="col.field"
                          :header="col.header">
                          <template #body="{ data, field }">
                            <InputText v-if="field == 'origem' || field == 'destino'" :disabled="blockComponent"
                              v-model="data[field]" :placeholder="col.header" class="w-full md:w-12rem" />
                            <Dropdown v-else-if="field == 'moeda'" :disabled="blockComponent" v-model="data[field]"
                              :options="transfersCurrency" :placeholder="col.header" optionLabel="label"
                              class="w-full md:w-12rem" />
                            <InputNumber v-else-if="field == 'valor'" :useGrouping="true" :disabled="blockComponent"
                              v-model="data[field]" :minFractionDigits="2" :maxFractionDigits="2"
                              :placeholder="col.header" class="customInputNumber" />
                            <Dropdown v-else-if="field == 'categoria'" :disabled="blockComponent" v-model="data[field]"
                              :options="transferCategory" :placeholder="col.header" optionLabel="label"
                              class="w-full md:w-14rem">
                              <template #option="slotProps">
                                <div>
                                  {{ slotProps.option.data.i18n && slotProps.option.data.i18n[this.language] !== undefined
                                    ? slotProps.option.data.i18n[this.language] : slotProps.option.label }}
                                </div>
                              </template>
                            </Dropdown>
                          </template>
                        </Column>
                        <Column>
                          <template #header>
                            <div class="flex">
                              <div class="m-2">
                                <Button :disabled="blockComponent" @click="addLineDataTableFinancialTransfer"
                                  class="p-button p-component p-button-icon-only p-button-rounded p-button-text"
                                  :title="this.$langGetValue('financialTransferModalAddLine')" icon="pi pi-plus" />
                              </div>
                              <div class="m-2">
                                <Button :disabled="blockComponent" @click="dataTableFinancialTransfer = []"
                                  class="p-button p-component p-button-icon-only p-button-rounded p-button-text"
                                  :title="this.$langGetValue('buttonClean')" icon="pi pi-times-circle" />
                              </div>
                            </div>
                          </template>
                          <template #body="slotProps">
                            <div class="text-center">
                              <Button :disabled="blockComponent" @click="
                                deleteLineDataTableFinancialTransfer(
                                  slotProps.index
                                )
                                "
                                class="p-button p-component p-button-icon-only p-button-danger p-button-rounded p-button-text"
                                :title="this.$langGetValue('financialTransferModalDeleteLine')" icon="pi pi-times" />
                            </div>
                          </template>
                        </Column>
                      </DataTable>
                    </template>
                  </Card>
                  <template #footer>
                    <Button style="width: 100px; margin: 0; margin-left: 3.5px;" class="p-button p-component"
                      @click="cancelTransfers" :label="this.$langGetValue('buttonCancel')" />
                    <Button :disabled="blockComponent" style="width: 80px; margin: 0; margin-left: 3.5px;"
                      class="p-button p-component" @click="sendDataTableFinancialTransfer"
                      :label="this.$langGetValue('buttonSave')" />
                  </template>
                </Dialog>
              </div>

              <div v-if="financialTransferValue != null" style="width: 45%">
                <div class="flex flex-row flex-wrap">
                  <h5>
                    <!-- Resumo das Transferências -->
                    {{ this.$langGetValue('transfersResume') }}
                  </h5>
                </div>
                <div>
                  <DataView :value="financialTransferValue" paginator :rows="5">
                    <template #list="slotProps">
                      <div class="mt-2" style="width: 100%;">
                        <div class="flex">
                          <div><b><i>{{ slotProps.data.categoria }}</i></b> -</div>
                          <div style="margin-left: 5px"><i>{{ slotProps.data.quantidade }} {{
                            this.$langGetValue("financialTransferTotal") }}
                              {{ slotProps.data.valor.toLocaleString("pt-BR", {
                                style: "currency", currency:
                                  slotProps.data.moeda,
                              }) }}</i>
                          </div>
                        </div>
                      </div>
                    </template>
                  </DataView>
                </div>
              </div>
            </div>

            <div style="height: 50px"></div>

            <div class="flex">

              <div v-if="blockComponent == false" class="uploads" style="width: 40%">
                <div class="flex flex-row flex-wrap">
                  <h5>Uploads</h5>
                </div>
                <div style="
                margin-left: 10px;
                font-size: 0.8em;
                margin-top: -10px;
                margin-bottom: 10px;
              ">
                  {{ this.$langGetValue("uploadSub") }}
                </div>
                <div class="custom-uploader" style="margin-left: 10px">
                  <FileUpload name="demo[]" @remove="removeAttachment" @clear="this.incidentVariables.attachments = []"
                    :maxFileSize="10000000" :chooseLabel="this.$langGetValue('buttonSelect')"
                    :cancelLabel="this.$langGetValue('buttonClean')" :auto="true" :customUpload="true"
                    :showUploadButton="false" :multiple="true" @uploader="myUploader" @select="checkFiles">

                    <template #empty>
                      <div class="flex align-items-center justify-content-center flex-column">
                        <i class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400" />
                        <p class="mt-4 mb-0">{{ this.$langGetValue("uploadText") }}</p>
                      </div>
                    </template>
                  </FileUpload>
                </div>
              </div>
              <div v-if="blockComponent == false" style="width: 20%"></div>
              <div v-if="incidentDataLocal != undefined && incidentDataLocal.attachment != null" class="uploads">
                <div class="flex flex-row flex-wrap">
                  <h5 v-if="blockComponent == true">Uploads</h5>
                  <div v-else style="height: 35px"></div>
                </div>
                <div style="
                margin-left: 10px;
                font-size: 0.8em;
                margin-top: -10px;
                margin-bottom: 10px;
              ">
                  Arquivos anexados a este incidente.
                </div>
                <div>
                  <DataTable class="card" :value="incidentDataLocal.attachment" paginator :rows="3">
                    <Column field="name"></Column>
                    <Column field="createdTimestamp" class="text-center"></Column>
                    <Column :exportable="false" style="min-width: 8rem;">
                      <template #body="slotProps">
                        <div class="text-center">
                          <Button title="Baixar" icon="pi pi-download" class="p-button-rounded mr-2"
                            @click="downloadAttachment(slotProps.data)" />
                          <Button v-if="blockComponent == false" title="Excluir" icon="pi pi-times"
                            class="p-button-rounded mr-2" @click="deleteAttachment(slotProps.data)" />
                        </div>
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </div>
            </div>
            <div>
              <div v-if="incidentDataLocal == undefined" style="width: 45%; margin-top: 50px; ">
                <div class="flex flex-row flex-wrap">
                  <h5>{{ this.$langGetValue("cc") }}</h5>
                </div>
                <div style="
                margin-left: 10px;
                font-size: 0.8em;
                margin-top: -10px;
                margin-bottom: 10px;
              ">
                  <!-- Quem deve estar em cópia no email de registro do incidente? -->
                  {{ this.$langGetValue("ccSub") }}
                </div>
                <div class="customAutoCompleteEmail">
                  <AutoComplete :multiple="true" v-model="selectedUsersEmail" :suggestions="filteredUsers"
                    @complete="searchUsersLara($event, 'user|group')" :forceSelection="true" />
                </div>
              </div>
            </div>
            <div style="height: 90px"></div>
            <div class="registered-requested flex flex-row justify-content-between" style="width: 100%">
              <div ref="requestedBy" class="requested-by" style="width: 45%">
                <div class="flex align-items-center" style="gap:5px">
                  <i style="color: brown; cursor: help" title="Campo Obrigatório">*</i>
                  <div style="font-weight: 600; font-size: 1.25rem;">{{ this.$langGetValue("requestedBy") }}</div>
                </div>
                <div style="margin-left: 10px">
                  <div class="flex flex-row">
                    <AutoComplete v-model="incidentVariables.selectedRequestedBy"
                      :class="{ 'p-invalid': isInvalid.requestedBy, 'custom-auto-complete': true }"
                      :suggestions="filteredUsers" @complete="searchUsersLara($event)"
                      @click="incidentVariables.selectedRequestedBy = ''" :forceSelection="true"
                      :disabled="checkRole(['team.op-risk']) == false || blockComponent" />
                  </div>
                </div>
              </div>

              <div ref="registeredBy" class="registered-by" style="width: 45%">
                <div class="flex">
                  <div style="font-weight: 600; font-size: 1.25rem;">{{ this.$langGetValue("registeredBy") }}</div>
                </div>
                <div style="margin-left: 10px">
                  <div class="flex flex-row">
                    <AutoComplete v-model="incidentVariables.selectedRegisteredBy"
                      :class="{ 'p-invalid': isInvalid.registeredBy, 'custom-auto-complete': true }"
                      :suggestions="filteredUsers" @complete="searchUsersLara($event)"
                      @click="incidentVariables.selectedRegisteredBy = ''" :forceSelection="true" disabled />
                  </div>
                </div>
              </div>
            </div>

            <div style="height: 50px"></div>
            <div
              v-if="blockComponent == true && incidentDataLocal != undefined && incidentDataLocal.status != -1 && checkRole(['team.op-risk'])"
              class="flex justify-content-center flex-wrap">
              <Button class="p-button p-component p-button-danger" :label="this.$langGetValue('buttonRefuse')"
                @click="displayModalReject = true" />
            </div>
            <div v-if="blockComponent == false && incidentDataLocal == undefined"
              class="flex justify-content-center flex-wrap">
              <Button :disabled="sendIncidentButton" :label="this.$langGetValue('buttonSend')" @click="createIncident" />
            </div>
            <div v-if="blockComponent == false && editIncidentButton == true"
              class="flex justify-content-center flex-wrap">
              <Button @click="reloadSearchedIncidentPage" style="margin-right: 5px;"
                class="p-button p-component p-button-danger" :label="this.$langGetValue('buttonCancel')" />
              <Button :disabled="editIncidentButtonControl" @click="updateIncident" style="margin-left: 5px;"
                :label="this.$langGetValue('buttonSave')" />
            </div>
            <div v-if="blockComponent == false && reviewIncidentButton == true"
              class="flex justify-content-center flex-wrap">
              <Button @click="reloadSearchedIncidentPage" style="margin-right: 5px;"
                class="p-button p-component p-button-danger" :label="this.$langGetValue('buttonCancel')" />
              <Button @click="reviewIncident" :label="this.$langGetValue('buttonReview')" />
            </div>

            <div v-if="incidentDataLocal != undefined && incidentDataLocal.rmAdmin != null">
              <div class="flex align-items-center justify-content-between mb-3">
                <div class="text-900 font-medium text-xl">Integração rmAdmin</div>
              </div>
              <div class="mt-2" style="max-height: 250px; margin-left: 10px; overflow-x: auto;">
                <div class="flex align-items-center justify-content-start mt-1">
                  <b>Responsável</b>: {{ incidentDataLocal.rmAdmin.accountable }}
                </div>
                <div class="flex align-items-center justify-content-start mt-1">
                  <b>Aprovador</b>: {{ incidentDataLocal.rmAdmin.approvalResponsible }}
                </div>
                <div class="flex align-items-center justify-content-start mt-1">
                  <b>Id RMAdmin</b>: {{ incidentDataLocal.rmAdmin.adminIdPayment }}
                </div>
                <div class="flex align-items-center justify-content-start mt-1">
                  <b>Escritório Parceiro</b>: {{ incidentDataLocal.rmAdmin.aai }}
                </div>
              </div>
            </div>

            <div v-if="incidentDataLocal != undefined && incidentDataLocal.extraFields != null" style="height: 50px">
            </div>
            <Panel v-if="incidentDataLocal != undefined && incidentDataLocal.extraFields != null"
              :header="this.$langGetValue('extraFields')" collapsed toggleable>
              <ExtraFieldsComponent :keyValue="incidentDataLocal.extraFields"></ExtraFieldsComponent>
            </Panel>

            <div v-if="incidentDataLocal != undefined && blockComponent == true && incidentDataLocal.logs?.length > 0"
              style="height: 50px"></div>
            <div v-if="incidentDataLocal != undefined && blockComponent == true && incidentDataLocal.logs?.length > 0">
              <div class="flex align-items-center justify-content-between mb-3">
                <div class="text-900 font-medium text-xl">{{ this.$langGetValue('incidentHistory') }}</div>
              </div>
              <LogsComponent :logObject="this.incidentDataLocal.logs" :fieldTreatment="fieldMap"
                :logName="this.$langGetValue('incident')"
                :migrated="this.incidentDataLocal.integrationFrom.mode == 'migrate' ? true : false">
              </LogsComponent>
              <div v-if="1 == 2" class="flex p-2">
                <div class="flex col-11">
                  <Textarea v-model="value" autoResize rows="3" cols="30" placeholder="Adicionar comentário" />
                </div>
                <div class="flex col-1 justify-content-center align-items-center">
                  <Button style="height: 55px; width: 5rem;" icon="pi pi-send" rounded />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog class="emailDialog" v-model:visible="sendIncidentModal" style="width: 30vw" :modal="true"
        :showHeader="false" :dismissable-mask="false">
        <Vue3Lottie v-if="showMail == false" ref="lottieContainer" :animationData="emailAnimation" :loop="1" :height="200"
          :width="200" />
        <Card v-if="showMail" style="height:100%; border-radius: 6px">
          <template #title>
            <div style="width: 100%;" class="flex">
              <div style="width: 33%;"></div>
              <div style="width: 33%;" class="flex justify-content-center">
                <img src="images/emailPng.png" style="width: 60px; height:60px">
              </div>
              <div style="width: 33%;" class="flex justify-content-end">
                <Button style="color: grey" @click="sendIncidentModal = false"
                  class="p-button p-component p-button-icon-only p-button-rounded p-button-text" icon="pi pi-times" />
              </div>
            </div>
          </template>
          <template #content>
            <div>
              <div style="width:100%" class="p-float-label customAutoCompleteEmail">
                <AutoComplete :multiple="true" v-model="selectedUsersEmail" :suggestions="filteredUsers"
                  @complete="searchUsersLara($event, 'user|group')" :forceSelection="true" />
                <label><i class="pi pi-user" style="font-weight: 900;"></i>
                  {{ this.$langGetValue('users') }}</label>
              </div>
              <div style="width:100%" class="text-center mt-4">
                <Button @click="fowardMail" class="p-button p-component p-button-rounded" icon="pi pi-send"
                  :label="this.$langGetValue('fowardButton')" />
              </div>
            </div>
          </template>
        </Card>
      </Dialog>


    </div>
  </div>
</template>

<script>
import IssueService from "../service/IssueService"
import UserService from "../service/UserService";
import ArcherService from "../service/ArcherService";
import IncidentService from "../service/IncidentService.js";
import Util from "../service/Util";
import Quill from "quill";
import QuillBetterTable from "quill-better-table";
import LaraService from "../service/LaraService";
import MarioService from "../service/MarioService";
import { ref } from 'vue';
import EventBus from "../AppEventBus"
import { Vue3Lottie } from 'vue3-lottie';
import emailAnimation from '../assets/animations/AnimacaoEmail.json'
import thunderAnimation from '../assets/animations/AnimacaoThunder.json'
import LogsComponent from '../components/LogsComponent.vue'
import ExtraFieldsComponent from '../components/ExtraFieldsComponent.vue'
import ExpandMenu from "../components/ExpandMenu.vue"
import DragDialog from "../components/DragDialog.vue";
import ContentService from "../service/ContentService.js"
import ActionPlanComponent from "../components/ActionPlanComponent.vue";
// import HierarchyComponentVue from "./HierarchyComponent.vue";
import "../Global.css"


export default {
  components: {
    Vue3Lottie,
    LogsComponent,
    ExtraFieldsComponent,
    ExpandMenu,
    DragDialog,
    ActionPlanComponent
    // HierarchyComponentVue
  },

  data() {
    return {
      salesforce: false,
      salesforceUrl: null,
      showActionPlan: true,
      clickedAtReview: false,
      mouseInDescription: false,
      descriptionLength: 0,
      fieldMap: {
        "managerialImpact": {
          "label": this.$langGetValue("managerialImpact"),
          "isDate": false,
          "showChanges": true
        },
        "accountingDate": {
          "label": this.$langGetValue("accountingDate"),
          "isDate": true,
          "showChanges": true
        },
        "accountingAccount": {
          "label": this.$langGetValue("accountingAccount"),
          "isDate": false,
          "showChanges": true
        },
        "dateOfOccurrence": {
          "label": this.$langGetValue("dateOfOccurrence"),
          "isDate": true,
          "showChanges": true
        },
        "dateOfDiscovery": {
          "label": this.$langGetValue("dateOfDiscovery"),
          "isDate": true,
          "showChanges": true
        },
        "registeredBy": {
          "label": this.$langGetValue("registeredBy"),
          "isDate": false,
          "showChanges": true
        },
        "requestedBy": {
          "label": this.$langGetValue("requestedBy"),
          "isDate": false,
          "showChanges": true
        },
        "tweet": {
          "label": "Tweet",
          "isDate": false,
          "showChanges": false
        },
        "riskLevel": {
          "label": "Risco",
          "isDate": false,
          "showChanges": true
        },
        "status": {
          "label": "Status",
          "isDate": false,
          "showChanges": true
        },
        "reviewedBy": {
          "label": this.$langGetValue("reviewedBy"),
          "isDate": false,
          "showChanges": true
        },
        "reasonForNewRiskLevel": {
          "label": this.$langGetValue("forceRiskJustification"),
          "isDate": false,
          "showChanges": true
        },
        "submittedTimestamp": {
          "label": "Data de Submissão",
          "isDate": true,
          "showChanges": true
        },
        "updatedTimestamp": {
          "label": "Data de Atualização",
          "isDate": true,
          "showChanges": true
        },
        "eventOrigin": {
          "label": "Event Origin",
          "isDate": false,
          "showChanges": true
        },
        "tweetBrainSTORM": {
          "label": "Tweet BrainSTORM",
          "isDate": false,
          "showChanges": false
        },
        "riskFactor": {
          "label": "Risk Factor",
          "isDate": false,
          "showChanges": true
        },
        "capability": {
          "label": "Capability",
          "isDate": false,
          "showChanges": true
        },
        "company": {
          "label": this.$langGetValue("company"),
          "isDate": false,
          "showChanges": true
        },
        "description": {
          "label": this.$langGetValue("description"),
          "isDate": false,
          "showChanges": false
        },
        "descriptionSanitized": {
          "label": this.$langGetValue("description"),
          "isDate": false,
          "showChanges": false
        },
        "responsibleForReview": {
          "label": this.$langGetValue("responsibleToReview"),
          "isDate": false,
          "showChanges": true
        },
        "mario": {
          "label": this.$langGetValue("impacts"),
          "isDate": false,
          "showChanges": false
        },
        "system": {
          "label": this.$langGetValue("system"),
          "isDate": false,
          "showChanges": true
        },
        "hashtag": {
          "label": this.$langGetValue("hashtag"),
          "isDate": false,
          "showChanges": true
        },
        "product": {
          "label": this.$langGetValue("products"),
          "isDate": false,
          "showChanges": true
        },
        "transfer": {
          "label": this.$langGetValue("financialTransfer"),
          "isDate": false,
          "showChanges": false
        },
        "hierarchy": {
          "label": this.$langGetValue("hierarchy"),
          "isDate": false,
          "showChanges": true
        },
        "baselLevel": {
          "label": this.$langGetValue("basel"),
          "isDate": false,
          "showChanges": true
        },
        "attachment": {
          "label": "Upload",
          "isDate": false,
          "showChanges": true
        }
      },
      //Animations
      emailAnimation,
      thunderAnimation,
      // Todos os v-models
      incidentVariables: {
        reviewedDescription: null,
        relatedIncident: [],
        relatedIssue: [],
        actionPlans: [],
        accountingAccount: null,
        managerialImpact: null,
        accountingDate: null,
        riskChangeReason: null,
        riskLevel: null,
        dateOccurrence: null,
        dateDiscovery: null,
        selectedTransfersCurrency: null,
        selectedTransfersCategory: null,
        hashtagSuggestion: [],
        selectedHashtags: [],
        selectedBasel: null,
        selectedRiskFactor: null,
        selectedSystem: [],
        selectedProduct: [],
        selectedCapability: null,
        selectedCompany: null,
        selectedIBUs: null,
        selectedOBU: null,
        selectedHasTransfer: null,
        transfers: [],
        ope01_selected: null,
        ope02_selected: null,
        reg01_selected: null,
        rep01_selected: null,
        rep01_value: null,
        rep02_selected: null,
        fin01_selected: null,
        fin02_selected: null,
        fin03_selected: null,
        fin04_selected: null,
        selectedRequestedBy: null,
        selectedRegisteredBy: UserService.user,
        eventOriginValue: null,
        attachments: [],
        tweetValue: null,
      },
      editableTweet: null,
      displayModalReject: false,
      openNotepad: false,
      notepadDebounce: null,
      notepadInterval: null,
      processChange: null,
      notepadMenuItems: [
        {
          label: "Toggle background",
          // icon: "pi pi-copy",
          command: () => {
            this.$refs["drag-dialog"].toggleBG();
            this.$refs["context-menu"].hide();
            this.$refs["expand-menu"].collapseMenu();
          },
        },
        {
          label: "Reset position",
          icon: "pi pi-file-edit",
          command: () => {
            this.$refs["drag-dialog"].resetPosition();
            this.$refs["context-menu"].hide();
            this.$refs["expand-menu"].collapseMenu();
          },
        },
      ],
      loadingComponent: true,
      usersToAssign: [],
      riskOptions: [
        {
          label: "Baixo",
          risk: "low"
        },
        {
          label: "Médio",
          risk: "medium"
        },
        {
          label: "Alto",
          risk: "high"
        },
        {
          label: "Crítico",
          risk: "critical"
        },
      ],
      acceptTweet: false,
      oldTransfers: null,
      quillFocusControl: false,
      tweetBrainSTORM: null,
      tweetReplaceButton: false,
      tweetGenerateButton: false,
      displayModalTweet: false,
      editIncidentButtonControl: true,
      editIncidentButton: false,
      reviewIncidentButton: false,
      isInvalid: {},
      dialItems: [],
      notePad: null,
      showMail: true,
      selectedUsersEmail: [],
      sendIncidentModal: false,
      statusIncident: null,
      blockComponent: false,
      acceptedFiles: null,
      hasTransfer: [
        {
          label: this.$langGetValue("optionYes"),
        },
        {
          label: this.$langGetValue("optionNo"),
        },
      ],
      viewBaselOne: null,
      viewBaselTwo: null,
      hasFinancialTransferData: false,
      sendIncidentButton: false,
      resultRisk: { risk_level: null, mario_score: null },
      quill: null,
      transfersCurrency: null,
      transferCategory: null,
      incidenteLabel: "",
      refreshPageWatcher: null,
      financialTransferValue: null,
      dataTableFinancialTransferColumns: [
        { field: "origem", header: this.$langGetValue('financialTransferModalOriginAccount') },
        { field: "destino", header: this.$langGetValue('financialTransferModalDestinationAccount') },
        { field: "valor", header: this.$langGetValue('financialTransferModalValue') },
        { field: "moeda", header: this.$langGetValue('financialTransferModalCurrency') },
        { field: "categoria", header: this.$langGetValue('financialTransferModalCategory') }
      ],
      dataTableFinancialTransfer: [
        {
          origem: null,
          destino: null,
          valor: null,
        },
      ],
      basel: [],
      riskFactor: [],
      system: [],
      product: [],
      company: [],
      capabilities: [],
      selectedOBUChip: null,
      selectedIBUsChip: [],
      hierarchyOBU: [],
      hierarchyIBUs: [],
      expandedKeysOBU: {},
      expandedKeysIBUs: {},
      filteredHashtags: [],
      hashtags: [],
      files: [],
      users: ArcherService.users,
      filteredUsers: [],
      displayModalIncident: false,
      registeredID: null,
      lastFilterValueIBUs: "",
      intervalExpandKeysIBUs: null,
      intervalExpandKeysOBU: null,
      currentIndexTab: 0,
      currentIndexOpe: [0],
      currentIndexReg: [0],
      currentIndexRep: [0],
      currentIndexFin: [0],
      ope01: null,
      ope02: null,
      reg01: null,
      rep01: null,
      rep02: null,
      fin01: null,
      fin02: null,
      fin03: null,
      fin04: null,
      fin03_currency: null,
      opRiskValue: 0,
      regRiskValue: 0,
      repRiskValue: 0,
      finRiskValue: 0,
      totalRiskValue: 0,
      tempTotaRiskData: {},
      disableOp02: true,
      disableRep02: true,
      maxRep: 100,
      maxFin: 100,
      maxTotal: 100,
      incidentVariablesOLD: null,
      viewOBUChip: {},
      viewIBUsChip: [],
      language: null,
    };
  },
  watch: {
    dataTableFinancialTransfer: {
      handler: function () {
        if (this.dataTableFinancialTransfer.length == 0) {
          this.addLineDataTableFinancialTransfer()
        }
      },
      deep: true,
    },
    incidentVariables: {
      handler: function () {
        // Código que atualiza o cálculo do Mario de acordo com área selecionada
        // console.log(this.incidentVariables.actionPlans)

        if (this.incidentVariables.selectedRiskFactor != null && this.incidentVariables.selectedRiskFactor.value == 262598 && this.incidentVariables.selectedOBU != null) {
          this.incidentVariables.selectedOBU = null

          this.valuateOpRisk()
          this.valuateRegRisk()
          this.valuateRepRisk()
          this.valuateFinRisk()
          this.valuateTotalRisk()
        }

        if (this.incidentVariablesOLD.dateOccurrence != this.incidentVariables.dateOccurrence) {
          this.incidentVariables.dateDiscovery = null
        }

        if (this.incidentVariables.selectedHasTransfer == this.hasTransfer[1] && this.incidentVariablesOLD.selectedHasTransfer == this.hasTransfer[0]) {
          this.incidentVariables.transfers = []
          this.financialTransferValue = null
        }

        if (!(JSON.stringify(this.incidentVariablesOLD.hashtagSuggestion) === JSON.stringify(this.incidentVariables.hashtagSuggestion))) {
          for (let i = 0; i < this.incidentVariables.hashtagSuggestion.length; i++) {
            if (this.incidentVariables.hashtagSuggestion[i].charAt(0) != "#") {
              this.incidentVariables.hashtagSuggestion[i] = "#" + this.incidentVariables.hashtagSuggestion[i]
            }
          }
        }

        this.sendIncidentButton = false

        this.editIncidentButtonControl = false

        this.incidentVariablesOLD = { ...this.incidentVariables }
      },
      deep: true,
    },
    fin03_currency() {
      if (this.incidentVariables.fin03_selected !== null) {
        this.verifyFin03()
      }
    },
    dateOccurrence() {
      this.incidentVariables.dateDiscovery = null;
    },
  },
  props: {
    incidentData: Object,
    incidentMode: String
  },
  setup(props) {
    const incidentDataLocal = ref(props.incidentData);
    const incidentModeLocal = ref(props.incidentMode);
    return {
      incidentDataLocal,
      incidentModeLocal
    };
  },
  mounted() {
    this.mountPage();
    console.log(this.incidentData)
    document.addEventListener('copy', this.handleCopyDescription);
    this.isMounted = true;
  },
  unmounted() {
    clearInterval(this.refreshPageWatcher)
    clearInterval(this.intervalExpandKeysIBUs);
    clearInterval(this.intervalExpandKeysOBU);
    clearInterval(this.notepadInterval)
  },
  methods: {
    mountPage() {
      Util.loading = true

      if (this.$route.query.ownerId != undefined || this.$route.query.caseId != undefined) {
        this.salesforce = true
      }

      this.notepadDebounce = Util.debounce(() => this.sendNotepad())

      this.language = ContentService.currentLang

      this.product = IncidentService.incidentFields.product.data.sort((a, b) => a.label.localeCompare(b.label));

      const npIndex = this.product.findIndex(item => item.id === 607);

      if (npIndex !== -1) {
        [this.product[0], this.product[npIndex]] = [this.product[npIndex], this.product[0]];
      }

      this.system = IncidentService.incidentFields.system.data.sort((a, b) => a.label.localeCompare(b.label));
      this.capabilities = IncidentService.incidentFields.capability.data.sort((a, b) => a.label.localeCompare(b.label));

      this.basel = IncidentService.incidentFields.baselLevel.data
      this.riskFactor = IncidentService.incidentFields.riskFactor.data

      this.orderTree(IncidentService.incidentFields.hierarchy.data)

      this.hierarchyIBUs = IncidentService.incidentFields.hierarchy.data
      this.hierarchyOBU = IncidentService.incidentFields.hierarchy.data

      let tempArrayCompany = []

      IncidentService.incidentFields.company.data.forEach((currentCompany) => {
        if (!tempArrayCompany.find(obj => obj.label === "---" + currentCompany.data.group + "---")) {
          tempArrayCompany.push({
            label: "---" + currentCompany.data.group + "---",
            children: []
          })
        }
      })

      IncidentService.incidentFields.company.data.forEach((currentCompany) => {
        tempArrayCompany.forEach((currentGroup, index) => {
          if (currentGroup.label === "---" + currentCompany.data.group + "---") {
            tempArrayCompany[index].children.push(currentCompany)
          }
        })
      })

      this.hashtags = IncidentService.incidentFields.hashtag.data

      this.ope01 = IncidentService.incidentFields.ope01.data.sort((a, b) => a.data.stormId.localeCompare(b.data.stormId))
      this.ope02 = IncidentService.incidentFields.ope02.data.sort((a, b) => a.data.stormId.localeCompare(b.data.stormId))
      this.reg01 = IncidentService.incidentFields.reg01.data.sort((a, b) => a.data.stormId.localeCompare(b.data.stormId))
      this.rep01 = IncidentService.incidentFields.rep01.data.sort((a, b) => a.data.stormId.localeCompare(b.data.stormId))
      this.rep02 = IncidentService.incidentFields.rep02.data.sort((a, b) => a.data.stormId.localeCompare(b.data.stormId))
      this.fin01 = IncidentService.incidentFields.fin01.data.sort((a, b) => a.data.stormId.localeCompare(b.data.stormId))
      this.fin02 = IncidentService.incidentFields.fin02.data.sort((a, b) => a.data.stormId.localeCompare(b.data.stormId))
      this.fin03 = IncidentService.incidentFields.currency.data
      this.fin04 = IncidentService.incidentFields.fin04.data
      this.fin03_currency = this.fin03[0];

      this.company = tempArrayCompany
      this.transfersCurrency = IncidentService.incidentFields.currency.data
      this.transferCategory = IncidentService.incidentFields.transferCategory.data

      IncidentService.acceptedFiles().then((resp) => {
        this.acceptedFiles = resp
      })
      this.incidentVariablesOLD = { ...this.incidentVariables }
      this.refreshPageWatcher = setInterval(() => this.resetData(), 100);
      Quill.register(
        {
          "modules/better-table": QuillBetterTable,
        },
        true
      );
      this.setQuill();
      this.intervalExpandKeysIBUs = setInterval(() => this.expandKeysIBUs(), 100);
      this.intervalExpandKeysOBU = setInterval(() => this.expandKeysOBU(), 100);

      this.checkRole(['team.op-risk']) == false ? this.incidentVariables.selectedRequestedBy = UserService.user : null

      Util.loading = false

      this.searchedIncident()
    },
    mouseEnterContextMenu() {
      this.$refs["expand-menu"].expandMenu();
    },
    mouseLeaveContextMenu() {
      this.$refs["expand-menu"].collapseMenu();
      this.$refs["context-menu"].hide();
    },
    onNotepadMenuRightClick(event) {
      this.$refs["context-menu"].show(event);
    },
    async forceUpdateActionPlan() {
      this.showActionPlan = false;

      await this.$nextTick();

      this.showActionPlan = true;
    },
    searchedIncident() {
      if (this.incidentDataLocal == undefined) {
        return
      }

      Util.loading = true

      if (this.incidentDataLocal.hierarchy == null) {
        this.incidentDataLocal.hierarchy = []
      }

      this.incidentRisk = this.incidentDataLocal.riskLevel

      this.registeredID = this.incidentDataLocal.id

      let tempUsersMail = []
      this.incidentDataLocal.logs?.forEach((log) => {
        tempUsersMail.push(log.user)
      })

      if (this.incidentDataLocal.attachment != null) {
        for (var key in this.incidentDataLocal.attachment) {
          this.incidentDataLocal.attachment[key]["createdTimestamp"] = this.convertDate(this.incidentDataLocal.attachment[key]["createdTimestamp"])
        }
      }
      this.incidenteLabel = this.incidentDataLocal.name

      this.$emit('incident-data', this.incidenteLabel, this.incidentRisk, this.getMarioSeverityColor(this.incidentRisk));

      this.setQuillOnlyRead()
      this.setQuillNotepad()

      this.notepadInterval = setInterval(() => this.setSize(), 100);

      this.notepad.root.innerHTML = this.incidentDataLocal.notepad

      if (this.incidentDataLocal.notepad != '<p><br></p>' && this.incidentDataLocal.notepad != null) {
        var element = document.querySelector(".notepadExpand");

        if (element) {
          var expander = element.querySelector(".expander");
          var menu = element.querySelector(".menu-content");

          if (expander) {
            expander.style.backgroundColor = 'rgb(0, 30, 98)';
            expander.style.color = 'white';
          }
          if (menu) {
            menu.style.backgroundColor = 'rgb(0, 30, 98)';
            menu.style.color = 'white';
          }
        }
      }

      this.incidentVariables.dateOccurrence = new Date(this.incidentDataLocal.dateOfOccurrence); // Data de Ocorrencia

      setTimeout(() => {
        this.incidentVariables.dateDiscovery = new Date(this.incidentDataLocal.dateOfDiscovery); // Data de 
      }, 100);

      this.quill.root.innerHTML = this.incidentDataLocal.description // Descrição

      if(this.incidentDataLocal.reviewedDescription != null) {
        this.incidentVariables.reviewedDescription = this.incidentDataLocal.reviewedDescription
      }

      if (this.incidentDataLocal.hashtag != null) {
        setTimeout(() => {  // hashtags
          for (let i = 0; i < this.hashtags.length; i++) {
            this.incidentDataLocal.hashtag.forEach((hashtag) => {
              if (hashtag.id == this.hashtags[i].id && hashtag.status == 1) { // Status 1 = hashtag
                this.incidentVariables.selectedHashtags.push(this.hashtags[i])
              }
            })
          }

          this.incidentDataLocal.hashtag.forEach((hashtag) => {
            if (hashtag.status == 2) { //Status 2 = sugestão
              this.incidentVariables.hashtagSuggestion.push(hashtag.name)
            }
          })
        }, 200);
      }

      let tempBaselOne = null
      let tempBaselTwo = null
      this.basel.forEach((basel) => { // Basileia
        if (basel.id == this.incidentDataLocal?.baselLevel?.levelOne) {
          tempBaselOne = basel.data.i18n && basel.data.i18n[this.language] != undefined ? basel.data.i18n[this.language] : basel.label
          basel.children.forEach((child) => {
            if (child.id == this.incidentDataLocal?.baselLevel?.levelTwo) {
              this.incidentVariables.selectedBasel = child
              tempBaselTwo = child.data.i18n && child.data.i18n[this.language] != undefined ? child.data.i18n[this.language] : child.label
            }
          })
        }
      })

      this.viewBaselOne = tempBaselOne
      this.viewBaselTwo = tempBaselTwo

      this.riskFactor.forEach((risk) => { // risk
        if (risk.id == this.incidentDataLocal.riskFactor) {
          this.incidentVariables.selectedRiskFactor = risk
        }
      })

      if (this.incidentDataLocal.system != null) {
        this.system.forEach((system) => { // system
          if (this.incidentDataLocal.system.includes(system.id)) {
            this.incidentVariables.selectedSystem.push(system)
          }
        })
      }

      if (this.incidentDataLocal.product != null) {
        this.product.forEach((product) => { // product
          if (this.incidentDataLocal.product.includes(product.id)) {
            this.incidentVariables.selectedProduct.push(product)
          }
        })
      }

      this.capabilities.forEach((capability) => { // capability
        if (capability.id == this.incidentDataLocal.capability) {
          this.incidentVariables.selectedCapability = capability
        }
      })

      this.company.forEach((company) => { // company
        company.children.forEach((children) => {
          if (children.id == this.incidentDataLocal.company) {
            this.incidentVariables.selectedCompany = children
          }
        })
      })

      function visitNodes(tree, path = []) {
        if (!tree) {
          return [];
        }

        path.push(tree.label);
        const info = {
          id: tree.id,
          label: tree.label,
          path: path.join(' -> ')
        };

        const result = [info];

        if (tree.children && tree.children.length > 0) {
          for (const children of tree.children) {
            result.push(...visitNodes(children, path.slice()));
          }
        }

        return result;
      }

      let hierarchyArray = [] // IBUs e OBU

      for (let i = 0; i < this.hierarchyIBUs.length; i++) {
        hierarchyArray = hierarchyArray.concat(visitNodes(this.hierarchyIBUs[i]));
      }

      if (this.incidentDataLocal.hierarchy != null) {
        this.incidentDataLocal.hierarchy.forEach((tempHierarchy) => {
          const area = hierarchyArray.find(object => object.id === tempHierarchy.id && tempHierarchy.type === 'originated');
          if (area) {
            this.viewOBUChip = {
              label: area.label,
              path: area.path,
              id: area.id
            }
          }
        })

        this.incidentDataLocal.hierarchy.forEach((tempHierarchy) => {
          const area = hierarchyArray.find(object => object.id === tempHierarchy.id && tempHierarchy.type === 'impacted');
          if (area) {
            this.viewIBUsChip.push({
              label: area.label,
              path: area.path,
              id: area.id
            })
          }
        })
      }

      setTimeout(() => {

        // Mario

        this.currentIndexOpe = [0, 1];
        this.disableOp02 = false;

        this.incidentVariables.ope01_selected = this.incidentDataLocal.mario.ope01.id
        this.incidentVariables.ope02_selected = this.incidentDataLocal.mario.ope02.id

        this.disableReg = false

        this.incidentVariables.reg01_selected = this.incidentDataLocal.mario.reg01.id

        this.disableRep = false
        this.disableRep02 = false

        this.currentIndexRep = [0, 1];

        if (this.incidentDataLocal.mario.rep01.id != null) {
          this.incidentVariables.rep01_selected = this.incidentDataLocal.mario.rep01.id;
        }
        else {
          this.incidentVariables.rep01_selected = "input";
          this.incidentVariables.rep01_value = this.incidentDataLocal.mario.rep01.value;
        }

        this.incidentVariables.rep02_selected = this.incidentDataLocal.mario.rep02.id;

        this.disableFin = false

        this.currentIndexFin = [0, 1, 2];

        this.incidentVariables.fin01_selected = this.incidentDataLocal.mario.fin01.id;

        if (Object.keys(this.incidentDataLocal.mario.fin02).length != 0) {
          this.incidentVariables.fin02_selected = this.incidentDataLocal.mario.fin02.id;
        }

        this.fin03.forEach((currency) => {
          if (currency.id == this.incidentDataLocal.mario.fin03.currency) {
            this.fin03_currency = currency
          }
        })

        this.incidentVariables.fin03_selected = this.incidentDataLocal.mario.fin03.value;

        setTimeout(() => {
          this.incidentVariables.fin04_selected = this.incidentDataLocal.mario.fin04.id;
        }, 300)

        let tempOBUIndex = this.incidentDataLocal.hierarchy.findIndex(objeto => objeto.type === "originated")

        let tempArrayIBU = []

        this.incidentDataLocal.hierarchy.forEach((area) => {
          if (area.type == "impacted") {
            tempArrayIBU.push(area.id)
          }
        })

        let tempMarioPayload = {
          "ope01": {
            "id": this.incidentDataLocal.mario.ope01.id
          },
          "ope02": {
            "id": this.incidentDataLocal.mario.ope02.id
          },
          "reg01": {
            "id": this.incidentDataLocal.mario.reg01.id
          },
          "rep01": {
            "id": this.incidentDataLocal.mario.rep01.id,
            "value": this.incidentDataLocal.mario.rep01.value
          },
          "rep02": {
            "id": this.incidentDataLocal.mario.rep02.id
          },
          "fin01": {
            "id": this.incidentDataLocal.mario.fin01.id
          },
          "fin02": {
            "id": this.incidentDataLocal.mario.fin02.id
          },
          "fin04": {
            "id": this.incidentDataLocal.mario.fin04.id
          },
          "obu": tempOBUIndex !== -1 ? this.incidentDataLocal.hierarchy[tempOBUIndex].id : null,
          "ibu": tempArrayIBU,
        }

        if (this.incidentDataLocal.mario.fin03.value != null) {
          tempMarioPayload.fin03 = {
            "currency": this.incidentDataLocal.mario.fin03.currency,
            "value": this.incidentDataLocal.mario.fin03.value
          }
        }

        MarioService.getScore(tempMarioPayload).then((response) => {
          this.opRiskValue = response.data.ope_total_score.toFixed(1);
          this.regRiskValue = response.data.reg_total_score.toFixed(1);
          this.repRiskValue = response.data.rep_total_score.toFixed(1);
          this.finRiskValue = response.data.fin_total_score.toFixed(1);

          this.maxTotal =
            response.data.mario_score.toFixed(1) > 100
              ? response.data.mario_score.toFixed(1)
              : 100;
          this.totalRiskValue = response.data.mario_score.toFixed(1);
          this.tempTotaRiskData = tempMarioPayload
          this.resultRisk = response.data;
        });

      }, 200);

      if (this.incidentDataLocal.reasonForNewRiskLevel != null) {
        this.incidentVariables.riskLevel = this.riskOptions.find(objeto => objeto.risk === this.incidentDataLocal.riskLevel)
        this.incidentVariables.riskChangeReason = this.incidentDataLocal.reasonForNewRiskLevel
      }


      if (this.incidentDataLocal.actionPlan != null) {

        this.incidentVariables.actionPlans = this.incidentDataLocal.actionPlan
        this.forceUpdateActionPlan()
      }

      if (this.incidentDataLocal.transfer != null) { //transfers
        this.incidentVariables.selectedHasTransfer = this.hasTransfer[0]

        let tempArray = []
        this.incidentDataLocal.transfer.forEach((transfer) => {

          if (transfer.status == 1) {
            tempArray.push({
              id: transfer.id,
              origem: transfer.sender,
              destino: transfer.receiver,
              moeda: this.transfersCurrency.find(objeto => objeto.id === transfer.currency),
              valor: transfer.amount,
              categoria: this.transferCategory.find(objeto => objeto.id === transfer.transferCategory),
              requestedBy: transfer.requestedBy,
              registeredBy: transfer.registeredBy
            });
          }

        })

        this.dataTableFinancialTransfer = tempArray
        this.sendDataTableFinancialTransfer()

      }
      else {
        this.incidentVariables.selectedHasTransfer = this.hasTransfer[1]
      }

      this.incidentVariables.selectedRequestedBy = this.incidentDataLocal.requestedBy // Requested by
      this.incidentVariables.selectedRegisteredBy = this.incidentDataLocal.registeredBy // Registered By
      this.incidentVariables.eventOriginValue = this.incidentDataLocal.eventOrigin // Event Origin
      this.incidentVariables.managerialImpact = this.incidentDataLocal.managerialImpact // Impacto Gerencial
      this.incidentVariables.accountingAccount = this.incidentDataLocal.accountingAccount // Contas Contabeis

      if (this.incidentDataLocal.accountingDate != null) {
        this.incidentVariables.accountingDate = new Date(this.incidentDataLocal.accountingDate) // Data de Contabilização
      }

      if (this.incidentDataLocal.relatedIncident != null) { // Incidentes relacionados
        this.incidentVariables.relatedIncident = this.incidentDataLocal.relatedIncident
      }

      if (this.incidentDataLocal.relatedIssue != null) { // Issues relacionados
        this.incidentVariables.relatedIssue = this.incidentDataLocal.relatedIssue
      }

      this.incidentVariables.tweetValue = this.incidentDataLocal.tweet // Tweet
      this.tweetBrainSTORM = this.incidentDataLocal.tweetBrainSTORM // Tweet

      if (this.incidentDataLocal.logs?.length > 0) {
        this.treatLogs()
      }

      this.blockComponents()

      Util.loading = false

      if (this.incidentDataLocal.status == -1) { // rejeitado

        this.dialItems.push({
          label: this.$langGetValue("fowardButton"),
          icon: 'pi pi-envelope',
          disabled: true,
          command: () => {
            this.sendIncidentModal = true
          }
        })

        if (this.incidentDataLocal.requestedBy == UserService.user || this.incidentDataLocal.registeredBy == UserService.user || this.checkRole(['team.op-risk'])) {
          this.dialItems.push({
            label: this.$langGetValue("buttonEdit"),
            icon: 'pi pi-pencil',
            command: () => {
              this.incidentModeLocal = 'edit'
              this.speedDialEdit()
            }
          })
        }
        else {
          this.dialItems.push({
            label: this.$langGetValue("buttonEdit2"),
            icon: 'pi pi-pencil',
            disabled: true,
            command: () => {
            }
          })
        }

        if (this.checkRole(['team.op-risk'])) {
          this.dialItems.push(
            {
              label: this.$langGetValue("buttonReview"),
              icon: 'pi pi-check',
              disabled: true,
              command: () => {
              }
            },
          )
        }

        if (UserService.roles.includes('inc.distribution') || UserService.roles.includes('admin')) {
          this.dialItems.push(
            {
              label: this.$langGetValue("buttonAssign"),
              icon: 'pi pi-user-edit',
              disabled: true,
              command: () => {
              }
            },)
        }
      }

      if (this.incidentDataLocal.status == 1) { //fechado
        this.dialItems.push({
          label: this.$langGetValue("fowardButton"),
          icon: 'pi pi-envelope',
          command: () => {
            this.sendIncidentModal = true
          }
        })

        if (this.incidentDataLocal.requestedBy == UserService.user || this.incidentDataLocal.registeredBy == UserService.user || this.checkRole(['team.op-risk'])) {
          this.dialItems.push({
            label: this.$langGetValue("buttonEdit"),
            icon: 'pi pi-pencil',
            command: () => {
              this.incidentModeLocal = 'edit'
              this.speedDialEdit()
            }
          })
        }
        else {
          this.dialItems.push({
            label: this.$langGetValue("buttonEdit2"),
            icon: 'pi pi-pencil',
            disabled: true,
            command: () => {
            }
          })
        }

        if (this.checkRole(['team.op-risk'])) {
          this.dialItems.push(
            {
              label: this.$langGetValue("buttonReview"),
              icon: 'pi pi-check',
              disabled: true,
              command: () => {
              }
            },
          )
        }

        this.dialItems.push(
          {
            label: this.$langGetValue("extraFieldsIncident"),
            icon: 'pi pi-table',
            disabled: false,
            command: () => {
              window.location.href = window.location.href + "&bdpo=true"
              location.reload();
            }
          },)

      }

      if (this.incidentDataLocal.status == 2) { //pendente de distribuição
        this.dialItems.push({
          label: this.$langGetValue("fowardButton"),
          icon: 'pi pi-envelope',
          command: () => {
            this.sendIncidentModal = true
          }
        })

        if (this.incidentDataLocal.requestedBy == UserService.user || this.incidentDataLocal.registeredBy == UserService.user || this.checkRole(['team.op-risk'])) {
          this.dialItems.push({
            label: this.$langGetValue("buttonEdit"),
            icon: 'pi pi-pencil',
            command: () => {
              this.incidentModeLocal = 'edit'
              this.speedDialEdit()
            }
          })
        }
        else {
          this.dialItems.push({
            label: this.$langGetValue("buttonEdit2"),
            icon: 'pi pi-pencil',
            disabled: true,
            command: () => {
            }
          })
        }

        if (this.checkRole(['team.op-risk'])) {
          this.dialItems.push(
            {
              label: this.$langGetValue("buttonReview"),
              icon: 'pi pi-check',
              command: () => {
                this.incidentModeLocal = 'review'
                this.speedDialReview()
              }
            },
          )
        }

        if (UserService.roles.includes('inc.distribution') || UserService.roles.includes('admin')) {
          this.dialItems.push(
            {
              label: this.$langGetValue("buttonAssign"),
              icon: 'pi pi-user-edit',
              command: () => {
                UserService.roUsers.forEach((user) => {
                  this.usersToAssign.push({
                    label: Util.emailToName(user),
                    command: () => {
                      Util.loading = true
                      IncidentService.distributeIncident([this.incidentDataLocal.id], user).then(() => {
                        Util.loading = false
                        window.location.reload();
                      })
                    }
                  })
                })

                this.$refs.menu.toggle(event);
              }
            },)
        }

        this.dialItems.push(
          {
            label: this.$langGetValue("extraFieldsIncident"),
            icon: 'pi pi-table',
            disabled: false,
            command: () => {
              window.location.href = window.location.href + "&bdpo=true"
              location.reload();
            }
          },)

      }

      if (this.incidentDataLocal.status == 3) { //pendente de revisão
        this.dialItems.push({
          label: this.$langGetValue("fowardButton"),
          icon: 'pi pi-envelope',
          command: () => {
            this.sendIncidentModal = true
          }
        })

        if (this.incidentDataLocal.requestedBy == UserService.user || this.incidentDataLocal.registeredBy == UserService.user || this.checkRole(['team.op-risk'])) {
          this.dialItems.push({
            label: this.$langGetValue("buttonEdit"),
            icon: 'pi pi-pencil',
            command: () => {
              this.incidentModeLocal = 'edit'
              this.speedDialEdit()
            }
          })
        }
        else {
          this.dialItems.push({
            label: this.$langGetValue("buttonEdit2"),
            icon: 'pi pi-pencil',
            disabled: true,
            command: () => {
            }
          })
        }

        if (this.checkRole(['team.op-risk'])) {
          this.dialItems.push(
            {
              label: this.$langGetValue("buttonReview"),
              icon: 'pi pi-check',
              command: () => {
                this.incidentModeLocal = 'review'
                this.speedDialReview()
              }
            },
          )
        }

        if (UserService.roles.includes('inc.distribution') || UserService.roles.includes('admin')) {
          this.dialItems.push(
            {
              label: this.$langGetValue("buttonReAssign"),
              icon: 'pi pi-user-edit',
              command: () => {
                UserService.roUsers.forEach((user) => {
                  this.usersToAssign.push({
                    label: Util.emailToName(user),
                    command: () => {
                      Util.loading = true
                      IncidentService.distributeIncident([this.incidentDataLocal.id], user).then(() => {
                        Util.loading = false
                        window.location.reload();
                      })
                    }
                  })
                })

                this.$refs.menu.toggle(event);
              }
            },)
        }

        this.dialItems.push(
          {
            label: this.$langGetValue("extraFieldsIncident"),
            icon: 'pi pi-table',
            disabled: false,
            command: () => {
              window.location.href = window.location.href + "&bdpo=true"
              location.reload();
            }
          },)
      }

      if (this.incidentDataLocal.status == 4) { //revisado
        this.dialItems.push({
          label: this.$langGetValue("fowardButton"),
          icon: 'pi pi-envelope',
          command: () => {
            this.sendIncidentModal = true
          }
        })

        if (this.incidentDataLocal.requestedBy == UserService.user || this.incidentDataLocal.registeredBy == UserService.user || this.checkRole(['team.op-risk'])) {
          this.dialItems.push({
            label: this.$langGetValue("buttonEdit"),
            icon: 'pi pi-pencil',
            command: () => {
              this.incidentModeLocal = 'edit'
              this.speedDialEdit()
            }
          })
        }
        else {
          this.dialItems.push({
            label: this.$langGetValue("buttonEdit2"),
            icon: 'pi pi-pencil',
            disabled: true,
            command: () => {
            }
          })
        }

        if (this.checkRole(['team.op-risk'])) {
          this.dialItems.push(
            {
              label: this.$langGetValue("buttonReview"),
              icon: 'pi pi-check',
              disabled: true,
              command: () => {
              }
            },
          )
        }

        if (UserService.roles.includes('inc.distribution') || UserService.roles.includes('admin')) {
          this.dialItems.push(
            {
              label: this.$langGetValue("buttonReAssign"),
              icon: 'pi pi-user-edit',
              disabled: true,
              command: () => {
              }
            },)
        }

        this.dialItems.push(
          {
            label: this.$langGetValue("extraFieldsIncident"),
            icon: 'pi pi-table',
            disabled: false,
            command: () => {
              window.location.href = window.location.href + "&bdpo=true"
              location.reload();
            }
          },)

      }

      // setTimeout(() => {
      //   this.editIncidentButtonControl = true
      // }, 250)

      setTimeout(() => {
        if (this.incidentModeLocal == 'edit' && (this.incidentDataLocal.requestedBy == UserService.user || this.checkRole(['team.op-risk']))) {
          this.speedDialEdit()
        }

        if (this.incidentModeLocal == 'review' && this.checkRole(['team.op-risk'])) {
          if (this.incidentDataLocal.status == 4) {
            this.$toast.add({
              severity: "error",
              summary: "Incidente já revisado",
              // detail: "Você está agora editando o incidente",
              life: 1000,
            });
            return
          }
          this.speedDialReview()
        }

        this.$refs.incidentLabel.scrollIntoView({ behavior: 'smooth' });

      }, 200)

    },
    speedDialEdit() {
      this.$toast.add({
        severity: "success",
        summary: this.$langGetValue('toast0'),
        // detail: "Você está agora editando o incidente",
        life: 1000,
      });

      this.unblockComponents()

      setTimeout(() => {
        this.editIncidentButtonControl = true
      }, 200)

      this.editIncidentButton = true
      this.reviewIncidentButton = false
    },
    speedDialReview() {
      this.$toast.add({
        severity: "success",
        summary: this.$langGetValue('toast1'),
        // detail: "Você está agora revisando o incidente",
        life: 1000,
      });

      this.unblockComponents()
      this.editIncidentButton = false
      this.reviewIncidentButton = true
    },
    handleCopyDescription() {
      if (this.mouseInDescription && this.incidentModeLocal == 'view') {
        const copiedText = window.getSelection().toString();
        Util.copyToClipboard(copiedText, copiedText)
      }
    },
    reloadSearchedIncidentPage() {
      // this.incidentModeLocal = 'view'
      EventBus.emit('searchIncidentByID', this.incidentDataLocal.id, 'view');
    },
    redirectSalesforceIncident() {
      window.location.href = this.salesforceUrl
    },
    blockComponents() {
      this.blockComponent = true
    },
    unblockComponents() {
      this.blockComponent = false
      this.setQuill()
      this.forceUpdateActionPlan()
      this.quill.root.innerHTML = this.incidentDataLocal.description
      const editorElement = document.querySelector('.bodyDescription');
      editorElement.style.borderRadius = '0px 0px 8px 8px';
    },
    orderTree(tree) {
      if (tree.length > 0) {
        tree.sort((a, b) => {
          const labelA = a.label || '';
          const labelB = b.label || '';
          return labelA.localeCompare(labelB, 'pt-BR')
        })

        tree.forEach((child) => {
          this.orderTree(child?.children)
        })
      }
    },
    setQuill() {
      // Deletando o toolbar que fica na Div da descrição
      const elements = document.getElementsByClassName("ql-toolbar");
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }

      this.quill = new Quill("#editor-container", {
        placeholder: this.$langGetValue("descriptionText"),
        theme: "snow", // or 'bubble'
        modules: {
          table: false, // disable table module
          "better-table": {
            operationMenu: {
              items: {
                unmergeCells: {
                  text: "Another unmerge cells name",
                },
              },
            },
          },
          keyboard: {
            bindings: QuillBetterTable.keyboardBindings,
          },
        },
      });

      this.quill.root.innerHTML = "";

      const editorElement = document.querySelector('.bodyDescription');
      editorElement.style.borderRadius = '0px 0px 8px 8px';

      var span = document.createElement("span");
      span.style.color = "var(--surface-600)"

      this.quill.on('text-change', () => {
        this.editIncidentButtonControl = false

        this.descriptionLength = Util.stripHtml(this.quill.root.innerHTML).length
        span.innerHTML = this.descriptionLength

        if (span) {
          elements[0].appendChild(span);
        }
      })

      this.quill.on('selection-change', (event) => {

        if (event == null) {
          this.quillFocusControl = false
          this.generateTweet()
        }
        else {
          this.quillFocusControl = true
        }
      });

    },
    setQuillOnlyRead() {
      // Deletando o toolbar que fica na Div da descrição
      const elements = document.getElementsByClassName("ql-toolbar");
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }

      this.quill = new Quill("#editor-container", {
        theme: "snow", // or 'bubble'
        readOnly: true,
        modules: {
          toolbar: null
        }
      });

      this.quill.root.innerHTML = "";

      const editorElement = document.querySelector('.ql-editor');
      editorElement.style.opacity = '0.7';
      editorElement.style.pointerEvents = 'none';

      const editorElement2 = document.querySelector('.bodyDescription');
      editorElement2.style.borderRadius = '8px';

    },
    validateRelatedIncident(event) {
      var newChip = event.value;

      if (Array.isArray(newChip)) {
        newChip = newChip[newChip.length - 1]
      }

      if (/^[0-9]+$/.test(newChip) == false) {
        this.incidentVariables.relatedIncident.pop();

        this.$toast.add({
          severity: "error",
          summary: this.$langGetValue('insertValidId'),
          // detail: this.$langGetValue('toast8Sub'),
          life: 1000,
        })

        return
      }

      Util.loading = true
      IncidentService.getIncident(newChip).then((response) => {
        Util.loading = false

        if (!response.success) { // incidente não encontrado

          this.incidentVariables.relatedIncident.pop();

          this.$toast.add({
            severity: "info",
            summary: newChip + this.$langGetValue('notFound'),
            // detail: this.$langGetValue('toast8Sub'),
            life: 1000,
          })
        }
      });
    },
    validateRelatedIssue(event) {
      var newChip = event.value;

      if (Array.isArray(newChip)) {
        newChip = newChip[newChip.length - 1]
      }

      if (/^[0-9]+$/.test(newChip) == false) {
        this.incidentVariables.relatedIssue.pop();

        this.$toast.add({
          severity: "error",
          summary: this.$langGetValue('insertValidId'),
          // detail: this.$langGetValue('toast8Sub'),
          life: 1000,
        })

        return
      }

      Util.loading = true
      IssueService.getIssue(newChip).then((response) => {
        Util.loading = false

        if (!response.success) { // incidente não encontrado

          this.incidentVariables.relatedIssue.pop();

          this.$toast.add({
            severity: "info",
            summary: newChip + this.$langGetValue('notFound'),
            // detail: this.$langGetValue('toast8Sub'),
            life: 1000,
          })
        }
      });
    },
    setQuillNotepad() {
      this.notepad = new Quill("#notepad-container", {
        placeholder: "",
        theme: "snow", // or 'bubble'
        modules: {
          table: false, // disable table module
          "better-table": {
            operationMenu: {
              items: {
                unmergeCells: {
                  text: "Another unmerge cells name",
                },
              },
            },
            keyboard: {
              bindings: QuillBetterTable.keyboardBindings,
            },
          },
        },
      });

      const elements = document.getElementsByClassName("ql-toolbar");
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }

      const editorElement = document.querySelector('.comments');
      editorElement.style.border = '0px';

      this.notepad.root.innerHTML = "";

      this.notepad.on("text-change", () => {
        this.changeNotepadSaveIcon("not saved");
        this.notepadDebounce();
      });
    },
    setSize() {
      var cardDiv = document.querySelector('.notepadDialog .card');

      var editorDiv = document.querySelector('.comments .ql-editor');

      var heightCard = cardDiv.clientHeight;

      editorDiv.style.height = heightCard - 50 + "px";
    },
    sendNotepad() {
      IncidentService.notepad(
        this.incidentDataLocal.id,
        this.notepad.root.innerHTML
      ).then(() => {
        this.changeNotepadSaveIcon("saved");
      });
    },
    changeNotepadSaveIcon(state) {
      if (state == "not saved") {
        this.$refs["notepad-icon-save"].style.color = "#BBB";
      }
      if (state == "saved") {
        this.$refs["notepad-icon-save"].style.color = "#212121";
      }
    },
    copyDescription() {

      Util.copyToClipboard(this.quill.root.innerHTML, Util.stripHtml(this.quill.root.innerHTML))

      this.$toast.add({
        severity: "info",
        summary: this.$langGetValue('toast14'),
        // detail: this.$langGetValue('toast8Sub'),
        life: 1000,
      })
    },
    resetData() {
      if (Util.refreshPage == true) {
        Util.refreshPage = false;
        Object.assign(this.$data, this.$options.data.call(this));
        this.mountPage()
        this.$refs["incidentLabel"].scrollIntoView();
      }
    },
    // Início Mario
    getMarioSeverityColor(inc) {
      if (inc == "low") {
        return "#307AE0"
      }
      else if (inc == "medium") {
        return "#FFCA43"
      }
      else if (inc == "high") {
        return "#EB3E48"
      }
      else if (inc == "critical") {
        return "#754CC5"
      }
    },
    getStatus(status) {
      if (status == -1) {
        return this.$langGetValue('status0')
      }

      if (status == 1) {
        return this.$langGetValue('status1')
      }

      if (status == 2) {
        return this.$langGetValue('status2')
      }

      if (status == 3) {
        return this.$langGetValue('status3') + " - " + Util.emailToName(this.incidentDataLocal.responsibleForReview)
      }

      if (status == 4) {
        return this.incidentDataLocal.reviewedBy != null ? this.$langGetValue('status4') + " - " + Util.emailToName(this.incidentDataLocal.reviewedBy) : this.$langGetValue('status4')
      }
    },
    getStatusLogs(status) {
      if (status == -1) {
        return this.$langGetValue('status0')
      }

      if (status == 1) {
        return this.$langGetValue('status1')
      }

      if (status == 2) {
        return this.$langGetValue('status2')
      }

      if (status == 3) {
        return this.$langGetValue('status3')
      }

      if (status == 4) {
        return this.$langGetValue('status4')
      }
    },
    getStatusSeverity(status) {
      if (status == -1) {
        return "danger"
      }

      if (status == 1) {
        return "success"
      }

      if (status == 2) {
        return "warning"
      }

      if (status == 3) {
        return "warning"
      }

      if (status == 4) {
        return "success"
      }
    },
    clearOpRisk() {
      this.opRiskValue = 0;
      this.incidentVariables.ope02_selected = null;

      this.totalRiskValue = 0;
      this.resultRisk.risk_level = null
      this.incidentVariables.riskLevel = null
      this.incidentVariables.reasonForRiskLevel = null
      this.currentIndexOpe = [0, 1];
      this.disableOp02 = false;

      delete this.tempTotaRiskData.ope01;
      delete this.tempTotaRiskData.ope02;
    },
    clearRepRisk() {
      this.repRiskValue = 0;
      this.incidentVariables.rep02_selected = null;
      this.incidentVariables.rep01_value = null

      this.totalRiskValue = 0;
      this.resultRisk.risk_level = null
      this.incidentVariables.riskLevel = null
      this.incidentVariables.reasonForRiskLevel = null
      this.disableRep02 = false;
      this.currentIndexRep = [0, 1];

      delete this.tempTotaRiskData.rep01;
      delete this.tempTotaRiskData.rep02;
    },
    clearFinRisk() {
      this.finRiskValue = 0;
      this.incidentVariables.fin02_selected = null;
      this.incidentVariables.fin03_selected = null;

      this.totalRiskValue = 0;
      this.resultRisk.risk_level = null
      this.incidentVariables.riskLevel = null
      this.incidentVariables.reasonForRiskLevel = null
      this.currentIndexFin = [0, 1, 2];

      delete this.tempTotaRiskData.fin01;
      delete this.tempTotaRiskData.fin02;
      delete this.tempTotaRiskData.fin03;
    },
    valuateTotalRisk() {
      if (
        this.incidentVariables.ope01_selected != null && this.incidentVariables.ope02_selected != null && this.incidentVariables.reg01_selected != null &&
        ((this.incidentVariables.rep01_selected != null && this.incidentVariables.rep01_selected != "input") || (this.incidentVariables.rep01_value != null && this.incidentVariables.rep01_value != "")) &&
        this.incidentVariables.rep02_selected != null && this.incidentVariables.fin01_selected != null && (this.incidentVariables.fin01_selected != 4 || this.incidentVariables.fin02_selected != null) &&
        (this.incidentVariables.fin03_selected != null || this.incidentVariables.fin02_selected == 100)
      ) {

        // Preenchendo as obus e ibus

        this.tempTotaRiskData.obu = null

        if (this.incidentVariables.selectedRiskFactor != null && this.incidentVariables.selectedRiskFactor?.id != 4) {
          this.tempTotaRiskData.obu = this.incidentVariables.selectedOBU !== null ? parseInt(Object.keys(this.incidentVariables.selectedOBU)[0]) : this.viewOBUChip.id
        }
        this.tempTotaRiskData.ibu = (this.incidentVariables.selectedIBUs !== null && Object.keys(this.incidentVariables.selectedIBUs).length > 0) ? Object.keys(this.incidentVariables.selectedIBUs).map((num) => parseInt(num)) : this.viewIBUsChip.map((chip) => parseInt(chip.id))

        MarioService.getScore(this.tempTotaRiskData).then((response) => {
          this.maxTotal =
            response.data.mario_score.toFixed(1) > 100
              ? response.data.mario_score.toFixed(1)
              : 100;
          this.totalRiskValue = response.data.mario_score.toFixed(1);
          this.resultRisk = response.data;
        });

      } else if (this.finRiskValue !== 0) {
        // this.$toast.add({
        //   severity: "error",
        //   summary: "Existem opções em branco!",
        //   life: 1000,
        // });
        return;
      }
    },
    valuateOpRisk(form = false) {

      if (form) {
        this.currentIndexTab = 1
      }

      setTimeout(() => {
        if (this.incidentVariables.ope01_selected != null && this.incidentVariables.ope02_selected != null) {
          let tempData = {
            ope01: {
              id: this.incidentVariables.ope01_selected
            },

            ope02: {
              id: this.incidentVariables.ope02_selected
            }
          };

          // preecher ibu e obus

          tempData.obu = null

          if (this.incidentVariables.selectedRiskFactor != null && this.incidentVariables.selectedRiskFactor?.id != 4) {
            tempData.obu = this.incidentVariables.selectedOBU !== null ? parseInt(Object.keys(this.incidentVariables.selectedOBU)[0]) : this.viewOBUChip.id
          }
          tempData.ibu = (this.incidentVariables.selectedIBUs !== null && Object.keys(this.incidentVariables.selectedIBUs).length > 0) ? Object.keys(this.incidentVariables.selectedIBUs).map((num) => parseInt(num)) : this.viewIBUsChip.map((chip) => parseInt(chip.id))


          MarioService.getScore(tempData).then((response) => {
            this.opRiskValue = response.data.mario_score.toFixed(1);
            this.disableReg = false;

            this.tempTotaRiskData.ope01 = tempData.ope01
            this.tempTotaRiskData.ope02 = tempData.ope02;

            this.valuateTotalRisk();
          });
        }
      }, 200);
    },
    valuateRegRisk(form = false) {

      if (form) {
        this.currentIndexTab = 2
      }

      setTimeout(() => {
        if (this.incidentVariables.reg01_selected != null) {
          delete this.tempTotaRiskData.reg01;

          let tempData = {
            reg01: {
              id: this.incidentVariables.reg01_selected
            }
          };

          // preecher ibu e obus

          tempData.obu = null

          if (this.incidentVariables.selectedRiskFactor != null && this.incidentVariables.selectedRiskFactor?.id != 4) {
            tempData.obu = this.incidentVariables.selectedOBU !== null ? parseInt(Object.keys(this.incidentVariables.selectedOBU)[0]) : this.viewOBUChip.id
          }
          tempData.ibu = (this.incidentVariables.selectedIBUs !== null && Object.keys(this.incidentVariables.selectedIBUs).length > 0) ? Object.keys(this.incidentVariables.selectedIBUs).map((num) => parseInt(num)) : this.viewIBUsChip.map((chip) => parseInt(chip.id))

          MarioService.getScore(tempData).then((response) => {
            this.regRiskValue = response.data.mario_score.toFixed(1);
            this.disableRep = false;

            this.tempTotaRiskData.reg01 = tempData.reg01;

            this.valuateTotalRisk();
          });
        }
      }, 200);
    },
    valuateRepRisk(form = false) {

      if (form) {
        this.currentIndexTab = 3
      }

      setTimeout(() => {
        // console.log(this.incidentVariables.rep01_selected, this.incidentVariables.rep01_value)
        if (this.incidentVariables.rep01_selected == "input" && (this.incidentVariables.rep01_value == "" || this.incidentVariables.rep01_value == null)) {
          // console.log("Entrou")
          return
        }

        if (this.incidentVariables.rep01_selected != null && this.incidentVariables.rep02_selected != null) {
          let tempRep01 = null;

          this.incidentVariables.rep01_selected == "input"
            ? (tempRep01 = { value: this.incidentVariables.rep01_value })
            : (tempRep01 = { id: this.incidentVariables.rep01_selected });

          let tempData = {
            rep01: tempRep01,
            rep02: { id: this.incidentVariables.rep02_selected },
          };

          // preecher ibu e obus

          tempData.obu = null

          if (this.incidentVariables.selectedRiskFactor != null && this.incidentVariables.selectedRiskFactor?.id != 4) {
            tempData.obu = this.incidentVariables.selectedOBU !== null ? parseInt(Object.keys(this.incidentVariables.selectedOBU)[0]) : this.viewOBUChip.id
          }
          tempData.ibu = (this.incidentVariables.selectedIBUs !== null && Object.keys(this.incidentVariables.selectedIBUs).length > 0) ? Object.keys(this.incidentVariables.selectedIBUs).map((num) => parseInt(num)) : this.viewIBUsChip.map((chip) => parseInt(chip.id))

          MarioService.getScore(tempData).then((response) => {
            this.maxRep =
              response.data.mario_score.toFixed(1) > 100
                ? response.data.mario_score.toFixed(1)
                : 100;
            this.repRiskValue = response.data.mario_score.toFixed(1);
            this.disableFin = false;

            this.tempTotaRiskData.rep01 = tempData.rep01;
            this.tempTotaRiskData.rep02 = tempData.rep02

            this.valuateTotalRisk();
          });
        }
      }, 200);
    },
    valuateFinRisk() {
      setTimeout(() => {
        console.log(this.incidentVariables.fin04_selected)
        let tempData = null;

        if (this.incidentVariables.fin01_selected != null) {

          tempData = {
            fin01: this.incidentVariables.fin01_selected !== null ? { id: this.incidentVariables.fin01_selected } : null,
            fin02: this.incidentVariables.fin02_selected !== null ? { id: this.incidentVariables.fin02_selected } : null,
            fin03: this.incidentVariables.fin03_selected !== null ? { value: this.incidentVariables.fin03_selected, currency: this.fin03_currency.id } : null,
            fin04: this.incidentVariables.fin04_selected !== null ? { id: this.incidentVariables.fin04_selected } : null
          }

          // preecher ibu e obus

          tempData.obu = null

          if (this.incidentVariables.selectedRiskFactor != null && this.incidentVariables.selectedRiskFactor?.id != 4) {
            tempData.obu = this.incidentVariables.selectedOBU !== null ? parseInt(Object.keys(this.incidentVariables.selectedOBU)[0]) : this.viewOBUChip.id
          }
          tempData.ibu = (this.incidentVariables.selectedIBUs !== null && Object.keys(this.incidentVariables.selectedIBUs).length > 0) ? Object.keys(this.incidentVariables.selectedIBUs).map((num) => parseInt(num)) : this.viewIBUsChip.map((chip) => parseInt(chip.id))

          MarioService.getScore(tempData).then((response) => {
            this.maxFin =
              response.data.mario_score.toFixed(1) > 100
                ? response.data.mario_score.toFixed(1)
                : 100;
            this.finRiskValue = response.data.mario_score.toFixed(1);
            this.tempTotaRiskData.fin01 = tempData.fin01;
            this.tempTotaRiskData.fin02 = tempData.fin02;
            this.tempTotaRiskData.fin04 = tempData.fin04;

            if (this.incidentVariables.fin03_selected != null) {
              this.tempTotaRiskData.fin03 = tempData.fin03;
            }

            this.valuateTotalRisk();
          });
        }
      }, 200);
    },
    verifyFin02() {
      setTimeout(() => {
        this.incidentVariables.fin03_selected = null
        this.tempTotaRiskData.fin03 = null
        if (this.incidentVariables.fin02_selected == 100) {
          this.valuateFinRisk();
        }
      }, 200);
    },
    verifyFin03() {
      setTimeout(() => {
        this.incidentVariables.fin04_selected = null
        this.tempTotaRiskData.fin04 = null
        this.totalRiskValue = 0;
        this.resultRisk.risk_level = null

        if (this.incidentVariables.fin03_selected <= 0) {
          this.incidentVariables.fin03_selected = null

          this.$toast.add({
            severity: "error",
            summary: this.$langGetValue('invalidValue'),
            life: 1000,
          });
          return
        }

        if (this.incidentVariables.fin01_selected != 1 && this.incidentVariables.fin01_selected != 3) {
          this.valuateFinRisk();
        }
      }, 200);
    },
    //Fim do Mario
    removeAllIBUs() {
      this.incidentVariables.selectedIBUs = {};
      this.selectedIBUsChip = [];

      this.valuateOpRisk()
      this.valuateRegRisk()
      this.valuateRepRisk()
      this.valuateFinRisk()
      this.valuateTotalRisk()
    },
    chipRemoveIBUs(node) {
      let chave = node.key;

      delete this.incidentVariables.selectedIBUs[chave];

      this.chipUnselectionIBUs(node);
    },
    chipSelectionOBU(node) {
      this.selectedOBUChip = node.label;

      this.valuateOpRisk()
      this.valuateRegRisk()
      this.valuateRepRisk()
      this.valuateFinRisk()
      this.valuateTotalRisk()
    },
    chipSelectionIBUs(node) {
      if (this.viewIBUsChip.length != 0 && this.selectedIBUsChip.length == 0) {
        setTimeout(() => {
          this.viewIBUsChip.forEach((chip) => {
            if (chip.label != node.label)
              this.incidentVariables.selectedIBUs[chip.id] = true

            this.selectedIBUsChip.push({
              key: chip.id,
              label: chip.label
            });
          })
        }, 100)
      }

      this.selectedIBUsChip.push({
        key: node.key,
        label: node.label,
      });

      this.valuateOpRisk()
      this.valuateRegRisk()
      this.valuateRepRisk()
      this.valuateFinRisk()
      this.valuateTotalRisk()
    },
    chipUnselectionIBUs(node) {
      this.selectedIBUsChip.forEach((chip, index) => {
        if (chip.key == node.key) {
          this.selectedIBUsChip.splice(index, 1);
        }
      });

      this.valuateOpRisk()
      this.valuateRegRisk()
      this.valuateRepRisk()
      this.valuateFinRisk()
      this.valuateTotalRisk()
    },
    searchUsersLara(event, filter = "user") {
      let tempArray = [];

      LaraService.searchUser(event.query, filter).then((response) => {
        response.forEach((user) => {
          if (user.attributes.mail != "") {
            tempArray.push(user.attributes.mail.toLowerCase());
          }
        });
        this.filteredUsers = tempArray;
      });
    },
    cancelTransfers() {
      setTimeout(() => {
        if (this.oldTransfers != null) {
          this.dataTableFinancialTransfer = []

          this.oldTransfers.forEach((row) => {
            let copyObj = { ...row };
            this.dataTableFinancialTransfer.push(copyObj);
          })
        }
        this.hasFinancialTransferData = false
      }, 200)
    },
    sendDataTableFinancialTransfer() {
      setTimeout(() => {

        if (this.incidentVariables.selectedHasTransfer == this.hasTransfer[1]) {
          return
        }

        this.oldTransfers = []

        this.dataTableFinancialTransfer.forEach((row) => {
          let copyObj = { ...row };
          this.oldTransfers.push(copyObj);
        })

        let lineWithError = false
        let tempArray = [];
        this.dataTableFinancialTransfer.forEach((row) => {
          if (row.origem != null && row.destino != null && row.moeda != null && row.valor != null && row.categoria != null) {
            tempArray.push({
              "id": row.id != null ? row.id : null,
              "transferCategory": row.categoria.id,
              "currency": row.moeda.id,
              "sender": row.origem,
              "receiver": row.destino,
              "amount": row.valor,
              "amountBRL": row.moeda.id == 1 ? row.valor : null,
              "requestedBy": row.requestedBy != null ? row.requestedBy : null,
              "registeredBy": row.registeredBy != null ? row.registeredBy : null,
            });
          }
          else {
            lineWithError = true
          }
        });

        if (lineWithError && tempArray.length > 0) {
          this.$toast.add({
            severity: "error",
            summary: this.$langGetValue('toast2'),
            life: 1000,
          });
          return
        }

        if (tempArray.length == 0) {
          this.$toast.add({
            severity: "error",
            summary: this.$langGetValue('toast2'),
            life: 1000,
          });

          return
        }

        this.hasFinancialTransferData = false

        this.incidentVariables.transfers = tempArray

        let tempArray2 = {}

        this.dataTableFinancialTransfer.forEach((row) => {
          const key = `${row.categoria.label}-${row.moeda.label}`;
          if (tempArray2[key]) {
            tempArray2[key].valor += row.valor;
            tempArray2[key].quantidade = tempArray2[key].quantidade + 1
          }
          else {
            tempArray2[key] = { categoria: row.categoria.label, moeda: row.moeda.label, valor: row.valor, quantidade: 1 }
          }
        })

        this.financialTransferValue = Object.values(tempArray2);

      }, 200);
    },
    fillCurrency() {
      for (let i = 0; i < this.dataTableFinancialTransfer.length; i++) {
        this.dataTableFinancialTransfer[i].moeda = this.incidentVariables.selectedTransfersCurrency
      }
    },
    fillCategory() {
      for (let i = 0; i < this.dataTableFinancialTransfer.length; i++) {
        this.dataTableFinancialTransfer[i].categoria = this.incidentVariables.selectedTransfersCategory
      }
    },
    pasteDataTableFinancialTransfer() {
      this.dataTableFinancialTransfer = [];

      Util.getClipboard().then((text) => {

        let rows = text.split("\n");
        rows.forEach((row) => {
          if (row != "") {
            let rowToInsert = {};
            var cells = row.split("\t");

            rowToInsert["origem"] = cells[0];
            rowToInsert["destino"] = cells[1];
            rowToInsert["valor"] = parseFloat(cells[2]);
            rowToInsert["moeda"] = this.transfersCurrency.find(objeto => objeto.label === cells[3])
            rowToInsert["categoria"] = this.transferCategory.find(objeto => objeto.label.trim() === cells[4].trim())

            this.dataTableFinancialTransfer.push(rowToInsert);
          }
        });
        this.deleteLineDataTableFinancialTransfer(0)
      });
    },
    addLineDataTableFinancialTransfer() {
      this.dataTableFinancialTransfer.unshift({
        id: null,
        origem: null,
        destino: null,
        moeda: null,
        valor: null,
        categoria: null,
        requestedBy: null,
        registeredBy: null,
      });
    },
    deleteLineDataTableFinancialTransfer(index) {
      this.dataTableFinancialTransfer.splice(index, 1);
    },
    downloadTransferTemplate() {
      import('!!url-loader!../assets/static/modelo_transferencias.xlsm').then(dataUrl => {
        const blob = this.dataURLToBlob(dataUrl.default);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'modelo_transferencias.xlsm';
        link.click();
      });
    },
    dataURLToBlob(dataURL) {
      const byteString = atob(dataURL.split(',')[1]);
      const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    checkRole(roles) {
      return UserService.checkUserRoleAny(roles)
    },
    assignIncident() {
      this.usersToAssign = []

      UserService.roUsers.forEach((user) => {
        this.usersToAssign.push({
          label: Util.emailToName(user),
          command: () => {
            Util.loading = true
            IncidentService.distributeIncident([this.incidentDataLocal.id], user).then(() => {
              Util.loading = false
              window.location.reload();
            })
          }
        })
      })

      this.$refs.menu.toggle(event);
    },
    assignIncidentToMe() {
      this.usersToAssign = []

      this.usersToAssign.push({
        label: Util.emailToName(UserService.user),
        command: () => {
          Util.loading = true
          IncidentService.distributeIncident([this.incidentDataLocal.id], UserService.user).then(() => {
            Util.loading = false
            window.location.reload();
          })
        }
      })

      this.$refs.menu.toggle(event);
    },
    checkOBU() {
      return (
        this.incidentVariables.selectedRiskFactor != null &&
        this.incidentVariables.selectedRiskFactor?.id != 4
      );
    },
    checkSystem() {
      return (
        this.incidentVariables.selectedRiskFactor != null &&
        this.incidentVariables.selectedRiskFactor?.id == 1
      );
    },
    formatDate(dateInput, complete) {
      return Util.formatDate(dateInput, complete)
    },
    expandKeysIBUs() {
      if (this.blockComponent == true) {
        return
      }
      let el = document.querySelector(
        "#ibus-tree > div.p-tree-filter-container > input"
      );
      if (this.lastFilterValueIBUs == el.value) {
        return;
      }
      // console.log(el, el.value)
      this.lastFilterValueIBUs = el.value;
      if (el.value == "") {
        this.expandedKeysIBUs = {};
      } else {
        this.expandAllKeysIBUs();
      }
    },
    expandAllKeysIBUs() {
      for (let node of this.hierarchyIBUs) {
        this.expandNodeIBUs(node);
      }

      this.expandedKeysIBUs = { ...this.expandedKeysIBUs };
    },
    expandNodeIBUs(node) {
      if (node.children && node.children.length && !node.label.includes(this.lastFilterValueIBUs.toUpperCase())) {
        this.expandedKeysIBUs[node.key] = true;

        for (let child of node.children) {
          this.expandNodeIBUs(child);
        }
      }
    },
    expandKeysOBU() {
      if (this.blockComponent == true) {
        return
      }
      let el = document.querySelector(
        "#obu-tree > div.p-tree-filter-container > input"
      );
      if (this.lastFilterValueOBU == el.value) {
        return;
      }
      this.lastFilterValueOBU = el.value;
      if (el.value == "") {
        this.expandedKeysOBU = {};
      } else {
        this.expandAllKeysOBU();
      }
    },
    expandAllKeysOBU() {
      for (let node of this.hierarchyOBU) {
        this.expandNodeOBU(node);
      }

      this.expandedKeysOBU = { ...this.expandedKeysOBU };
    },
    expandNodeOBU(node) {
      if (node.children && node.children.length && !node.label.includes(this.lastFilterValueOBU.toUpperCase())) {
        this.expandedKeysOBU[node.key] = true;

        for (let child of node.children) {
          this.expandNodeOBU(child);
        }
      }
    },
    downloadAttachment(event) {
      IncidentService.getAttachment(event.id).then((resp) => {
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(resp);
        a.download = event.name;
        a.click();
      })
    },
    deleteAttachment(event) {
      this.incidentDataLocal.attachment = this.incidentDataLocal.attachment.filter(objeto => objeto.id !== event.id);
      this.editIncidentButtonControl = false
    },
    removeAttachment(event) {
      this.incidentVariables.attachments = this.incidentVariables.attachments.filter(objeto => objeto.filename !== event.file.name);
    },
    convertDate(date) {
      return new Date(date).toLocaleDateString('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      })
    },
    async readFileAsBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function () {
          const base64String = reader.result.split(',')[1];
          resolve(base64String);
        };

        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    async uploadOneFile(file, eventLength) {

      const base64String = await this.readFileAsBase64(file)

      IncidentService.uploadAttachment(
        file.name, base64String
      ).then((resp) => {
        this.incidentVariables.attachments.push({ id: resp.data, filename: file.name })
        if (this.incidentVariables.attachments.length == eventLength) {
          Util.loading = false
        }
      });
    },
    checkFile(file) {
      let result = false
      this.acceptedFiles.forEach((type) => {
        if (file.type.includes(type)) {
          result = true
        }
      })
      return result
    },
    checkFiles(event) {
      event.files.forEach((file, index) => {
        if (!this.checkFile(file)) {
          this.$toast.add({
            severity: "error",
            summary: this.$langGetValue('toast3'),
            detail: this.$langGetValue('toast3Sub') + file.name + "'",
            life: 1000,
          });
          event.files.splice(index, 1)
        }
      })
    },
    myUploader(event) {
      if (event.files.length == 0 || event.files.length == this.incidentVariables.attachments.length) {
        return
      }

      Util.loading = true;

      event.files.forEach((file) => {
        if (!this.incidentVariables.attachments.some(obj => obj.filename === file.name)) {
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = this.uploadOneFile(file, event.files.length)
          reader.onerror = function (error) {
            console.log("Error: ", error);
          };
        }
      });
    },
    treatLogs() {

      let logs = this.incidentDataLocal.logs

      logs.forEach(log => {
        if (log.changelog) {
          log.changelog.forEach(entry => {
            if (entry.field === "riskLevel") {
              entry.from = this.getMarioSeverity(entry.from);
              entry.to = this.getMarioSeverity(entry.to);
            }
            if (entry.field == "status") {
              entry.from = this.getStatusLogs(entry.from);
              entry.to = this.getStatusLogs(entry.to);
            }
          });
        }
      });
    },
    verifyTweet() {
      if (this.incidentVariables.tweetValue !== null && this.incidentVariables.tweetValue.length < 80) {
        this.$toast.add({
          severity: "error",
          summary: this.$langGetValue('toast4'),
          life: 2000,
        });
        return false
      }

      return true
    },
    regenerateTweet() {
      let tempDescription = Util.stripHtml(this.quill.root.innerHTML)

      this.editableTweet = null
      this.tweetReplaceButton = true
      this.tweetGenerateButton = true

      Util.loading = true
      MarioService.generateTweet(tempDescription).then((response) => {
        this.editableTweet = response?.data?.tweet
        if (this.tweetBrainSTORM == null) {
          this.editableTweet = "N/A"
        }

        this.tweetReplaceButton = false
        this.tweetGenerateButton = false

        Util.loading = false
      })
    },
    async openModalTweet() {
      Util.loading = true

      if (this.quillFocusControl === true || this.tweetBrainSTORM === null) {
        this.generateTweet()
      }

      while (this.tweetBrainSTORM === null) {
        await new Promise(resolve => setTimeout(resolve, 200));
      }

      Util.loading = false
      this.editableTweet = this.incidentVariables.tweetValue != null ? this.incidentVariables.tweetValue : this.tweetBrainSTORM
      this.displayModalTweet = true
    },
    acceptEditableTweet() {
      this.incidentVariables.tweetValue = this.editableTweet
      this.acceptTweet = true
      this.displayModalTweet = false

      if (this.clickedAtReview) {
        this.reviewIncident()
      }
    },
    generateTweet() {
      let tempDescription = Util.stripHtml(this.quill.root.innerHTML)
      if (tempDescription.length < 100) {
        return
      }

      this.tweetBrainSTORM = null

      MarioService.generateTweet(tempDescription).then((response) => {
        this.tweetBrainSTORM = response?.data?.tweet
        if (this.tweetBrainSTORM == null) {
          this.tweetBrainSTORM = "N/A"
        }
      })
    },
    fowardMail() {
      if (this.selectedUsersEmail.length == 0) {
        this.$toast.add({
          severity: "error",
          summary: this.$langGetValue('toast5'),
          detail: this.$langGetValue('toast5Sub'),
          life: 2000,
        });
        return
      }

      IncidentService.fowardIncident(this.selectedUsersEmail, this.incidentDataLocal.id).then(() => {
        this.showMail = false

        setTimeout(() => {
          this.showMail = true
          this.sendIncidentModal = false
        }, 4000)
        this.selectedUsersEmail = []
      })
    },
    getMarioSeverity(inc) {
      if (inc == "low") {
        return this.$langGetValue("lowMario");
      } else if (inc == "medium") {
        return this.$langGetValue("mediumMario");
      } else if (inc == "high") {
        return this.$langGetValue("highMario");
      } else if (inc == "critical") {
        return this.$langGetValue("criticalMario");
      }
    },
    async updateIncident() {
      Util.loading = true;

      if (this.incidentDataLocal.integrationFrom.application == 'storm') {
        let allValidate = this.validateFieldsSubmit();
        if (!allValidate) {
          Util.loading = false;
          return;
        }
      }

      if (this.quillFocusControl == true || this.tweetBrainSTORM === null) {
        this.generateTweet()
      }
      // Montando o payload

      // trava a função enquanto Tweet for null
      while (this.tweetBrainSTORM === null) {
        await new Promise(resolve => setTimeout(resolve, 200));
      }

      if (!this.verifyTweet()) {
        Util.loading = false;
        setTimeout(() => {
          this.openModalTweet()
        }, 200)
        return
      }

      var att = [];
      for (var key in this.incidentVariables.attachments) {
        att.push(this.incidentVariables.attachments[key]["id"]);
      }

      if (this.incidentDataLocal.attachment != null) {
        for (var keyA in this.incidentDataLocal.attachment) {
          att.push(this.incidentDataLocal.attachment[keyA]["id"]);
        }
      }

      var tempSystem = []
      this.incidentVariables.selectedSystem.forEach((system) => {
        tempSystem.push(system.id)
      })

      var tempProduct = []
      this.incidentVariables.selectedProduct.forEach((product) => {
        tempProduct.push(product.id)
      })

      var tempHashtag = []
      this.incidentVariables.selectedHashtags.forEach((hashtag) => {
        tempHashtag.push({ id: hashtag.id })
      })

      this.incidentVariables.hashtagSuggestion.forEach((hashtag) => {
        tempHashtag.push({ suggestion: hashtag })
      })

      var tempHierarchy = []

      if (this.incidentVariables.selectedRiskFactor?.id != 4) {  //Se não for external event
        if (this.incidentVariables.selectedOBU !== null) {
          tempHierarchy.push({
            type: "originated",
            id: parseInt(Object.keys(this.incidentVariables.selectedOBU)[0])
          })
        }
        else {
          if (Object.keys(this.viewOBUChip).length != 0) {
            tempHierarchy.push({
              type: "originated",
              id: this.viewOBUChip.id
            })
          }
        }
      }

      if (this.incidentVariables.selectedIBUs != null && Object.keys(this.incidentVariables.selectedIBUs).length > 0) {
        Object.keys(this.incidentVariables.selectedIBUs).forEach((area) => {
          tempHierarchy.push({
            type: "impacted",
            id: parseInt(area)
          })
        })
      }
      else {
        this.viewIBUsChip.forEach((area) => {
          tempHierarchy.push({
            type: "impacted",
            id: area.id
          })
        })
      }

      var tempBasel = null

      for (let i = 0; i < this.basel.length; i++) {
        for (let j = 0; j < this.basel[i].children.length; j++) {
          if (this.basel[i].children[j].id == this.incidentVariables.selectedBasel?.id) {
            tempBasel = {
              "levelOne": this.basel[i].id,
              "levelTwo": this.basel[i].children[j].id
            }
          }
        }
      }

      for (let i = 0; i < this.incidentVariables.transfers.length; i++) {
        if (this.incidentVariables.transfers[i].requestedBy == null) {
          this.incidentVariables.transfers[i].requestedBy = this.incidentVariables.selectedRequestedBy
        }
        if (this.incidentVariables.transfers[i].registeredBy == null) {
          this.incidentVariables.transfers[i].registeredBy = this.incidentVariables.selectedRegisteredBy
        }
      }


      let incidentChangesPayload = {
        dateOfOccurrence: this.incidentVariables.dateOccurrence,
        dateOfDiscovery: this.incidentVariables.dateDiscovery,
        tweet: this.incidentVariables.tweetValue,
        tweetBrainSTORM: this.tweetBrainSTORM,
        description: this.quill.root.innerHTML,
        riskFactor: this.incidentVariables.selectedRiskFactor?.id,
        capability: this.incidentVariables.selectedCapability?.id,
        company: this.incidentVariables.selectedCompany?.id,
        baselLevel: tempBasel,
        eventOrigin: this.incidentVariables.eventOriginValue,
        requestedBy: this.incidentVariables.selectedRequestedBy,
        registeredBy: this.incidentVariables.selectedRegisteredBy,
        system: tempSystem,
        product: tempProduct,
        hashtag: tempHashtag,
        hierarchy: tempHierarchy,
        mario: this.tempTotaRiskData,
        transfer: this.incidentVariables.transfers,
        riskLevel: this.incidentVariables.riskLevel != null ? this.incidentVariables.riskLevel.risk : null,
        reasonForNewRiskLevel: this.incidentVariables.riskChangeReason,
        attachment: att,
        managerialImpact: this.incidentVariables.managerialImpact,
        accountingDate: this.incidentVariables.accountingDate,
        accountingAccount: this.incidentVariables.accountingAccount,
        actionPlan: this.incidentVariables.actionPlans,
        relatedIncident: this.incidentVariables.relatedIncident.map(Number),
        relatedIssue: this.incidentVariables.relatedIssue.map(Number),
        reviewedDescription: this.incidentVariables.reviewedDescription
      };


      IncidentService.updateIncident(incidentChangesPayload, this.incidentDataLocal.id)
        .then((response) => {
          Util.loading = false;
          if (response.success) {
            this.incidentUpdatedResponse(response)
          }
          else {
            console.log(response)
            this.$toast.add({
              severity: "error",
              summary: this.$langGetValue('toast6'),
              detail: this.$langGetValue('toast6Sub'),
              life: 1000,
            });
          }
        })
        .catch((error) => {
          Util.loading = false;
          console.log(error);
          this.$toast.add({
            severity: "error",
            summary: this.$langGetValue('toast6'),
            detail: this.$langGetValue('toast6Sub'),
            life: 1000,
          });
        });


    },
    async reviewIncident() {
      Util.loading = true;

      let allValidate = this.validateFieldsSubmit();
      if (!allValidate) {
        Util.loading = false;
        return;
      }

      if (this.quillFocusControl == true || this.tweetBrainSTORM === null) {
        this.generateTweet()
      }
      // Montando o payload

      // trava a função enquanto Tweet for null
      while (this.tweetBrainSTORM === null) {
        await new Promise(resolve => setTimeout(resolve, 200));
      }

      if (!this.verifyTweet()) {
        Util.loading = false;
        setTimeout(() => {
          this.openModalTweet()
        }, 200)
        return
      }

      if (this.acceptTweet == false) {
        Util.loading = false;
        this.$toast.add({
          severity: "info",
          summary: "É necessário aceitar o tweet",
          // detail: this.$langGetValue('toast6Sub'),
          life: 10000,
        });

        setTimeout(() => {
          this.openModalTweet()
          this.clickedAtReview = true
        }, 200)
        return
      }

      var att = [];
      for (var key in this.incidentVariables.attachments) {
        att.push(this.incidentVariables.attachments[key]["id"]);
      }

      if (this.incidentDataLocal.attachment != null) {
        for (var keyA in this.incidentDataLocal.attachment) {
          att.push(this.incidentDataLocal.attachment[keyA]["id"]);
        }
      }

      var tempSystem = []
      this.incidentVariables.selectedSystem.forEach((system) => {
        tempSystem.push(system.id)
      })

      var tempProduct = []
      this.incidentVariables.selectedProduct.forEach((product) => {
        tempProduct.push(product.id)
      })

      var tempHashtag = []
      this.incidentVariables.selectedHashtags.forEach((hashtag) => {
        tempHashtag.push({ id: hashtag.id })
      })

      this.incidentVariables.hashtagSuggestion.forEach((hashtag) => {
        tempHashtag.push({ suggestion: hashtag })
      })

      var tempHierarchy = []

      if (this.incidentVariables.selectedRiskFactor?.id != 4) {  //Se não for external event
        if (this.incidentVariables.selectedOBU !== null) {
          tempHierarchy.push({
            type: "originated",
            id: parseInt(Object.keys(this.incidentVariables.selectedOBU)[0])
          })
        }
        else {
          if (Object.keys(this.viewOBUChip).length != 0) {

            tempHierarchy.push({
              type: "originated",
              id: this.viewOBUChip.id
            })
          }
        }
      }

      if (this.incidentVariables.selectedIBUs != null && Object.keys(this.incidentVariables.selectedIBUs).length > 0) {
        Object.keys(this.incidentVariables.selectedIBUs).forEach((area) => {
          tempHierarchy.push({
            type: "impacted",
            id: parseInt(area)
          })
        })
      }
      else {
        this.viewIBUsChip.forEach((area) => {
          tempHierarchy.push({
            type: "impacted",
            id: area.id
          })
        })
      }

      var tempBasel = null

      for (let i = 0; i < this.basel.length; i++) {
        for (let j = 0; j < this.basel[i].children.length; j++) {
          if (this.basel[i].children[j].id == this.incidentVariables.selectedBasel?.id) {
            tempBasel = {
              "levelOne": this.basel[i].id,
              "levelTwo": this.basel[i].children[j].id
            }
          }
        }
      }

      for (let i = 0; i < this.incidentVariables.transfers.length; i++) {
        if (this.incidentVariables.transfers[i].requestedBy == null) {
          this.incidentVariables.transfers[i].requestedBy = this.incidentVariables.selectedRequestedBy
        }
        if (this.incidentVariables.transfers[i].registeredBy == null) {
          this.incidentVariables.transfers[i].registeredBy = this.incidentVariables.selectedRegisteredBy
        }
      }

      let incidentReviewPayload = {
        dateOfOccurrence: this.incidentVariables.dateOccurrence,
        dateOfDiscovery: this.incidentVariables.dateDiscovery,
        tweetBrainSTORM: this.tweetBrainSTORM,
        tweet: this.incidentVariables.tweetValue,
        description: this.quill.root.innerHTML,
        riskFactor: this.incidentVariables.selectedRiskFactor?.id,
        capability: this.incidentVariables.selectedCapability?.id,
        company: this.incidentVariables.selectedCompany?.id,
        baselLevel: tempBasel,
        eventOrigin: this.incidentVariables.eventOriginValue,
        requestedBy: this.incidentVariables.selectedRequestedBy,
        registeredBy: this.incidentVariables.selectedRegisteredBy,
        system: tempSystem,
        product: tempProduct,
        hashtag: tempHashtag,
        hierarchy: tempHierarchy,
        mario: this.tempTotaRiskData,
        transfer: this.incidentVariables.transfers,
        riskLevel: this.incidentVariables.riskLevel != null ? this.incidentVariables.riskLevel.risk : null,
        reasonForNewRiskLevel: this.incidentVariables.riskChangeReason,
        attachment: att,
        managerialImpact: this.incidentVariables.managerialImpact,
        accountingDate: this.incidentVariables.accountingDate,
        rmAdmin: this.incidentDataLocal.rmAdmin != null ? this.incidentDataLocal.rmAdmin : null,
        extraFields: this.incidentDataLocal.extraFields != null ? this.incidentDataLocal.extraFields : null,
        accountingAccount: this.incidentVariables.accountingAccount,
        actionPlan: this.incidentVariables.actionPlans,
        relatedIncident: this.incidentVariables.relatedIncident.map(Number),
        relatedIssue: this.incidentVariables.relatedIssue.map(Number),
        reviewedDescription: this.incidentVariables.reviewedDescription
      };


      IncidentService.reviewIncident(incidentReviewPayload, this.incidentDataLocal.id)
        .then((response) => {
          Util.loading = false;
          if (response.success) {
            this.incidentReviewedResponse(response)
          }
          else {
            console.log(response);
            this.$toast.add({
              severity: "error",
              summary: this.$langGetValue('toast6'),
              detail: this.$langGetValue('toast6Sub'),
              life: 1000,
            });
          }
        })
        .catch((error) => {
          Util.loading = false;
          console.log(error);
          this.$toast.add({
            severity: "error",
            summary: this.$langGetValue('toast6'),
            detail: this.$langGetValue('toast6Sub'),
            life: 1000,
          });
        });

    },
    indicentCreatedResponse(resp) {
      this.displayModalIncident = true;
      this.registeredID = resp.data.id;
      if (this.salesforce) {
        this.salesforceUrl = resp.data.redirectURL
      }

      setTimeout(() => {
        this.sendIncidentButton = true
        this.incidenteLabel = "INC-" + this.registeredID;
        this.statusIncident = this.$langGetValue("statusCreated");
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }, 100)

    },
    incidentUpdatedResponse() {
      this.statusIncident = this.$langGetValue("statusUpdated")
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      this.displayModalIncident = true;
    },
    incidentReviewedResponse() {
      this.statusIncident = this.$langGetValue("statusReviewed")
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      this.displayModalIncident = true;
    },
    loadIncidentsMode(id, mode) {
      this.$router.push({ path: '/incident/search?', query: { id: id, mode: mode } });

      setTimeout(() => {
        window.location.reload();
      }, 200)
    },
    validateFieldsSubmit() {
      /////
      var editorContainer = null
      let emptyFields = [];

      if (this.incidentVariables.dateOccurrence == null) {
        emptyFields.push({ label: "dateOccurrence", text: this.$langGetValue("dateOfOccurrence") });
        this.isInvalid["dateOccurrence"] = true
      }
      else {
        this.isInvalid["dateOccurrence"] = false
      }
      //
      if (this.incidentVariables.dateDiscovery == null) {
        emptyFields.push({ label: "dateDiscovery", text: this.$langGetValue("dateOfDiscovery") });
        this.isInvalid["dateDiscovery"] = true
      }
      else {
        this.isInvalid["dateDiscovery"] = false
      }
      //

      if (this.quill.root.innerHTML == "<p><br></p>" || Util.stripHtml(this.quill.root.innerHTML).trim().length < 100) {
        emptyFields.push({ label: "description", text: this.$langGetValue("description") });

        editorContainer = document.getElementById('editor-container');
        editorContainer.style.border = '1px solid #e24c4c';
      }
      else {
        editorContainer = document.getElementById('editor-container');
        editorContainer.style.border = '';
      }
      //
      if (this.incidentVariables.selectedHashtags.length == 0 && this.incidentVariables.hashtagSuggestion.length == 0) {
        emptyFields.push({ label: "selectedHashtags", text: this.$langGetValue("hashtag") + " / " + this.$langGetValue("hashtagSuggestion") });
        this.isInvalid["selectedHashtags"] = true
      }
      else {
        this.isInvalid["selectedHashtags"] = false
      }
      //
      if (this.incidentVariables.selectedBasel == null) {
        emptyFields.push({ label: "selectedBasel", text: this.$langGetValue("basel") });
        this.isInvalid["selectedBasel"] = true
      }
      else {
        this.isInvalid["selectedBasel"] = false
      }
      //
      if (this.incidentVariables.selectedRiskFactor == null) {
        emptyFields.push({ label: "selectedRiskFactor", text: "Risk Factor" });
        this.isInvalid["selectedRiskFactor"] = true
      }
      else {
        this.isInvalid["selectedRiskFactor"] = false
      }
      //
      if (this.checkSystem() && this.incidentVariables.selectedSystem.length == 0) {
        emptyFields.push({ label: "selectedSystem", text: this.$langGetValue("system") });
        this.isInvalid["selectedSystem"] = true
      }
      else {
        this.isInvalid["selectedSystem"] = false
      }
      //
      if (this.incidentVariables.selectedProduct.length == 0) {
        emptyFields.push({ label: "selectedProduct", text: this.$langGetValue("products") });
        this.isInvalid["selectedProduct"] = true
      }
      else {
        this.isInvalid["selectedProduct"] = false
      }
      //
      if (this.incidentVariables.selectedCapability == null) {
        emptyFields.push({ label: "selectedCapability", text: "Capability" });
        this.isInvalid["selectedCapability"] = true
      }
      else {
        this.isInvalid["selectedCapability"] = false
      }
      //
      if (this.incidentVariables.selectedCompany == null) {
        emptyFields.push({ label: "selectedCompany", text: this.$langGetValue("company") });
        this.isInvalid["selectedCompany"] = true
      }
      else {
        this.isInvalid["selectedCompany"] = false
      }
      //
      if (this.checkOBU() && this.incidentVariables.selectedOBU == null) {
        if (this.incidentDataLocal == undefined) {
          emptyFields.push({ label: "selectedOBU", text: this.$langGetValue("originatedArea") });
          editorContainer = document.getElementById("obu-tree");
          editorContainer.style.border = '1px solid #e24c4c';
        }
        else {
          if (Object.keys(this.viewOBUChip).length == 0) {
            emptyFields.push({ label: "selectedOBU", text: this.$langGetValue("originatedArea") });
            editorContainer = document.getElementById("obu-tree");
            editorContainer.style.border = '1px solid #e24c4c';
          }
        }
      }
      else {
        editorContainer = document.getElementById("obu-tree");
        editorContainer.style.border = '';
      }
      //
      if (this.incidentDataLocal == undefined) {
        if ((this.incidentVariables.selectedIBUs == null || Object.keys(this.incidentVariables.selectedIBUs).length == 0)) {
          emptyFields.push({
            label: "selectedIBUs",
            text: this.$langGetValue("impactedAreas")
          });
          editorContainer = document.getElementById("ibus-tree");
          editorContainer.style.border = '1px solid #e24c4c';
        }
        else {
          editorContainer = document.getElementById("ibus-tree");
          editorContainer.style.border = '';
        }
      }
      else {
        if ((this.incidentVariables.selectedIBUs == null || Object.keys(this.incidentVariables.selectedIBUs).length == 0) && this.viewIBUsChip.length == 0) {
          emptyFields.push({
            label: "selectedIBUs",
            text: this.$langGetValue("impactedAreas")
          });
          this.isInvalid["selectedIBUs"] = true
        }
        else {
          this.isInvalid["selectedIBUs"] = false
        }
      }
      //
      if (this.totalRiskValue == 0) {
        emptyFields.push({
          label: "impact-mario",
          text: this.$langGetValue("impacts"),
        });

        editorContainer = document.getElementById("tabMario");
        editorContainer.style.border = '1px solid #e24c4c';
      }
      else {
        editorContainer = document.getElementById("tabMario");
        editorContainer.style.border = '';
      }

      if (this.incidentVariables.riskLevel != null && (this.incidentVariables.riskChangeReason == null || this.incidentVariables.riskChangeReason.trim() == "")) {
        emptyFields.push({
          label: "riskChangeReason",
          text: this.$langGetValue("forceRiskJustification")
        });
        this.isInvalid["riskChangeReason"] = true
      }
      else {
        this.isInvalid["riskChangeReason"] = false
      }

      if (this.reviewIncidentButton == true && this.checkRiskIsHighOrCritical() && this.incidentVariables.actionPlans.length == 0) {
        emptyFields.push({
          label: "actionPlan",
          text: this.$langGetValue("actionplan"),
        });
        this.isInvalid["actionPlan"] = true
      }
      else {
        this.isInvalid["actionPlan"] = false
      }

      if (this.incidentVariables.selectedHasTransfer == null || (this.incidentVariables.selectedHasTransfer == this.hasTransfer[0] && this.incidentVariables.transfers.length == 0)) {
        emptyFields.push({
          label: "selectedHasTransfer",
          text: this.$langGetValue("financialTransfer"),
        });
        this.isInvalid["selectedHasTransfer"] = true
      }
      else {
        this.isInvalid["selectedHasTransfer"] = false
      }

      // const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      // if (!emailRegex.test(this.incidentVariables.selectedRequestedBy)) {
      if (this.incidentVariables.selectedRequestedBy == null || this.incidentVariables.selectedRequestedBy.length == 0) {
        emptyFields.push({
          label: "requestedBy",
          text: this.$langGetValue("requestedBy"),
        });
        this.isInvalid["requestedBy"] = true
      }
      else {
        this.isInvalid["requestedBy"] = false
      }

      // if (!emailRegex.test(this.incidentVariables.selectedRegisteredBy)) {
      if (this.incidentVariables.selectedRegisteredBy != null && this.incidentVariables.selectedRegisteredBy != undefined && this.incidentVariables.selectedRegisteredBy.length == 0) {
        emptyFields.push({
          label: "registeredBy",
          text: this.$langGetValue("registeredBy"),
        });
        this.isInvalid["registeredBy"] = true
      }
      else {
        this.isInvalid["registeredBy"] = false
      }

      if (emptyFields.length > 0) {
        let message = this.$langGetValue('toast7Sub');
        emptyFields.forEach((field) => {
          message += ` ${field.text},`;
        });

        if (emptyFields.length == 0 || emptyFields == null) {
          return true;
        }

        try {
          this.$refs[emptyFields[0].label].$el.scrollIntoView();
        } catch (error) {
          this.$refs[emptyFields[0].label].scrollIntoView();
        }
        window.scrollBy(0, -130);
        this.$toast.add({
          severity: "error",
          summary: this.$langGetValue('toast7'),
          detail: message.slice(0, -1),
          life: 10000,
        });
        return false;
      }
      return true;
    },
    deleteIncident() {
      Util.loading = true;

      IncidentService.deleteIncident(this.incidentDataLocal.id).then(() => {
        window.location.reload()
      })
    },
    async createIncident() {
      Util.loading = true;

      let allValidate = this.validateFieldsSubmit();
      if (!allValidate) {
        Util.loading = false;
        return;
      }

      if (this.quillFocusControl == true) {
        this.generateTweet()
      }

      // Montando o payload

      // trava a função enquanto Tweet for null
      while (this.tweetBrainSTORM === null) {
        await new Promise(resolve => setTimeout(resolve, 200));
      }

      var att = [];
      for (var key in this.incidentVariables.attachments) {
        att.push(this.incidentVariables.attachments[key].id);
      }

      var tempSystem = []
      this.incidentVariables.selectedSystem.forEach((system) => {
        tempSystem.push(system.id)
      })

      var tempProduct = []
      this.incidentVariables.selectedProduct.forEach((product) => {
        tempProduct.push(product.id)
      })

      var tempHashtag = []
      this.incidentVariables.selectedHashtags.forEach((hashtag) => {
        tempHashtag.push({ id: hashtag.id })
      })

      this.incidentVariables.hashtagSuggestion.forEach((hashtag) => {
        tempHashtag.push({ suggestion: hashtag })
      })

      var tempHierarchy = []

      if (this.incidentVariables.selectedOBU !== null) {
        tempHierarchy.push({
          type: "originated",
          id: parseInt(Object.keys(this.incidentVariables.selectedOBU)[0])
        })
      }

      Object.keys(this.incidentVariables.selectedIBUs).forEach((area) => {
        tempHierarchy.push({
          type: "impacted",
          id: parseInt(area)
        })
      })

      var tempBasel = null

      for (let i = 0; i < this.basel.length; i++) {
        for (let j = 0; j < this.basel[i].children.length; j++) {
          if (this.basel[i].children[j].id == this.incidentVariables.selectedBasel?.id) {
            tempBasel = {
              "levelOne": this.basel[i].id,
              "levelTwo": this.basel[i].children[j].id
            }
          }
        }
      }

      for (let i = 0; i < this.incidentVariables.transfers.length; i++) {
        this.incidentVariables.transfers[i].requestedBy = this.incidentVariables.selectedRequestedBy
        this.incidentVariables.transfers[i].registeredBy = this.incidentVariables.selectedRegisteredBy
      }

      let incidentCreatePayload = {
        dateOfOccurrence: this.incidentVariables.dateOccurrence,
        dateOfDiscovery: this.incidentVariables.dateDiscovery,
        tweetBrainSTORM: this.tweetBrainSTORM,
        description: this.quill.root.innerHTML,
        riskFactor: this.incidentVariables.selectedRiskFactor?.id,
        capability: this.incidentVariables.selectedCapability?.id,
        company: this.incidentVariables.selectedCompany?.id,
        baselLevel: tempBasel,
        eventOrigin: this.incidentVariables.eventOriginValue,
        requestedBy: this.incidentVariables.selectedRequestedBy,
        registeredBy: this.incidentVariables.selectedRegisteredBy,
        system: tempSystem,
        product: tempProduct,
        hashtag: tempHashtag,
        hierarchy: tempHierarchy,
        mario: this.tempTotaRiskData,
        transfer: this.incidentVariables.transfers,
        attachment: att,
        riskLevel: this.incidentVariables.riskLevel != null ? this.incidentVariables.riskLevel.risk : null,
        reasonForNewRiskLevel: this.incidentVariables.riskChangeReason,
        carbonCopy: this.selectedUsersEmail,
        managerialImpact: this.incidentVariables.managerialImpact,
        accountingDate: this.incidentVariables.accountingDate,
        accountingAccount: this.incidentVariables.accountingAccount,
        actionPlan: this.incidentVariables.actionPlans,
        relatedIncident: this.incidentVariables.relatedIncident.map(Number),
        relatedIssue: this.incidentVariables.relatedIssue.map(Number),
      };

      if (this.salesforce) {
        incidentCreatePayload["salesforceFields"] = {
          "ownerId": this.$route.query.ownerId != undefined ? this.$route.query.ownerId : null,
          "caseId": this.$route.query.caseId != undefined ? this.$route.query.caseId : null
        }
      }

      IncidentService.createIncident(incidentCreatePayload)
        .then((response) => {
          Util.loading = false;
          if (response.success == true) {
            this.indicentCreatedResponse(response)
          }
          else {
            console.log(response)
            this.$toast.add({
              severity: "error",
              summary: this.$langGetValue('toast6'),
              detail: this.$langGetValue('toast6Sub'),
              life: 1000,
            });
          }
        }
        )
        .catch((error) => {
          Util.loading = false;
          console.log(error);
          this.$toast.add({
            severity: "error",
            summary: this.$langGetValue('toast6'),
            detail: this.$langGetValue('toast6Sub'),
            life: 1000,
          });
        });
    },
    addedFiles(event) {
      this.files = [];
      for (var i = 0; i < event.files.length; i++) {
        this.files.push(Util.getBase64(event.files[i]));
      }
    },
    removedFile(event) {
      this.files = [];
      for (var i = 0; i < event.files.length; i++) {
        this.files.push(Util.getBase64(event.files[i]));
      }
    },
    clearFiles() {
      this.files = [];
    },
    expandAll() {
      for (let node of this.nodes) {
        this.expandNode(node);
      }

      this.expandedKeysIBUs = { ...this.expandedKeysIBUs };
    },
    checkRiskIsHighOrCritical() {
      if (this.incidentVariables.riskLevel != null) {
        if (this.incidentVariables.riskLevel.risk == 'high' || this.incidentVariables.riskLevel.risk == 'critical') {
          return true
        }
      }
      else {
        if (this.resultRisk.risk_level == 'high' || this.resultRisk.risk_level == 'critical') {
          return true
        }
        return false
      }
    },
    searchHashtags(event) {
      // console.log(event)
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredHashtags = this.hashtags;
        } else {
          let tempArray = []

          this.hashtags.forEach((hashtag) => {
            if (hashtag.filter.some((filter) => filter.toLowerCase().includes(event.query.toLowerCase()))) {
              tempArray.push(hashtag)
            }
          })
          this.filteredHashtags = tempArray
        }
      }, 250);
    },
    updateQueryAP(event) {
      console.log("entrouu", event)
      this.incidentVariables.actionPlans = event;
    },
  },
};
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.glossaryDialog .p-dialog-content {
  background-color: rgba(0, 0, 0, 0)
}

.glossaryDialog {
  box-shadow: none
}

.glossaryDialog .p-fieldset-content {
  padding-top: 0 !important
}

.statusIncidentDialog .p-dialog-content {
  border-radius: 12px 12px 0px 0px;
}

.statusIncidentDialog .p-dialog-footer {
  border-radius: 0px 0px 12px 12px;
}

.emailDialog .p-dialog-content {
  background-color: rgba(0, 0, 0, 0)
}

.emailDialog {
  box-shadow: none
}

.p-button {
  background-color: #195ab4
}

.p-button:hover {
  background-color: #2860ad
}

.customAutoCompleteEmail .p-autocomplete,
.customAutoCompleteEmail .p-inputtext {
  width: 100%;
}

.customAutoCompleteEmail .p-inputtext .p-autocomplete-token {
  margin-top: 3px;
  margin-left: 3px
}

.dates {
  width: 100%;
}

.date-ocurrence {
  width: 50%;
}

.date-discovery {
  width: 50%;
}

.rf-system {
  width: 100%;
}

.risk-factor {
  width: 50%;
}

.system {
  width: 50%;
}

.product-capability {
  width: 100%;
}

.product {
  width: 50%;
}

.capability {
  width: 50%;
}

.registered-requested {
  width: 100%;
}

.registered-by {
  width: 50%;
}

.requested-by {
  width: 50%;
}

.FinancialTransfersDatatable {
  padding: 0 !important;
}

.capability-custom-dropdown .p-dropdown-item {
  padding: 0 !important;
}

.edited-tooltip-incident .p-tooltip-text {
  white-space: nowrap;
  width: fit-content;
}

.custom-uploader .p-fileupload {
  min-width: 500px !important;
  width: 40% !important;
}

.custom-uploader .p-fileupload .p-fileupload-row>div:first-child {
  display: none;
}

.bodyDescription {
  border-radius: 0px 0px 8px 8px;
}

.customTreeHierarchy .p-tree-container .p-treenode .p-treenode-content {
  padding: 0 !important;
}

.customTreeHierarchy .p-tree-container .p-treenode {
  border-style: none none none solid;
  border-color: #ced4da;
}

.description .ql-toolbar {

  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;

}

.customInputNumber .p-inputtext {
  width: 12rem
}

.notepad-icon-save {
  font-size: 1.1rem;
  color: #212121;
}

.notepad-icon-save {
  font-size: 1.1rem;
  color: #212121;
}

.custom-auto-complete input,
.custom-auto-complete {
  width: 100% !important;
}
</style>