import BackendService from './BackendService';

export default class MarioService {


    static getMarioSeverityColor(risk) {
        if (risk == "low") {
            return "#307AE0"
        }
        else if (risk == "medium") {
            return "#FFCA43"
        }
        else if (risk == "high") {
            return "#EB3E48"
        }
        else if (risk == "critical") {
            return "#754CC5"
        }
    }

    

    static getScore(data) {

        return BackendService.post("/risk/mario/scores", data).then(resp => {
            return resp
        })
    }

    static generateTweet(data) {

        let payload = {
            description: data
        }

        return BackendService.post("/llm/gpt/incident/generate-tweet", payload).then(resp => {
            return resp
        })
    }

    static summaryIncidents(data) {

        let payload = {
            engine: "storm-gpt-35-turbo-16k",
            ids: data
        }

        return BackendService.post("/llm/incident/incident-summary", payload).then(resp => {
            return resp
        })
    }


}